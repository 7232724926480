import React from 'react';
import { Formik, useFormikContext } from 'formik';

import CardDialog from '~/components/CardDialog';
import { LoadingSwitch } from '~/components/core';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import ArrayFieldFormik from '~/components/core/Formik/NestedFieldFormik/ArrayFieldFormik';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import type { FnolConfigurationPayload } from '~/components/SystemConfiguration/CliveConfiguration/Fnol/FnolConfigurationTypes';
import TextFieldFormik from '~/components/TextFieldFormik';
import type { OrganizationModel } from '~/components/types/organization-types';
import type { SubOrganization } from '~/components/types/sub-organization-types';
import useDataFetcher from '~/components/useDataFetcher';
import { getLobDescription } from '~/Utils/lobUtils';

import { useSysconfig } from '../../SystemConfigurationScreen';

interface SubOrgLobFnolConfigurationDialogProps {
  configurationId?: string;
  subOrganization: SubOrganization;
  lob: string;
  onSaveConfiguration: (configuration: FnolConfigurationPayload) => Promise<void>;
  onClose: () => void;
}

export const FnolConfigurationDialog: React.FC<SubOrgLobFnolConfigurationDialogProps> = ({
  configurationId,
  subOrganization,
  lob,
  onSaveConfiguration,
  onClose,
}) => {
  const { organization } = useSysconfig() as { organization: OrganizationModel };
  const configUrl = configurationId
    ? `/sysconfig/api/v1/organizations/${organization?.id}/service_ai/fnol_configuration/sub_org/${subOrganization.id}/configuration/${configurationId}`
    : null;

  const {
    isLoading,
    isError,
    data: configuration,
  } = useDataFetcher(configUrl, {}, Boolean(configurationId)) as {
    isLoading: boolean;
    isError: boolean;
    data: FnolConfigurationPayload | undefined;
    reloadData: () => void;
  };

  const { lobConfigurationsDict } = useLobConfiguration();

  const initialValues = configuration || {
    search_query_options: [],
  };

  const title = `FNOL Configuration for ${subOrganization.name} - ${getLobDescription(lob, lobConfigurationsDict)}`;

  return (
    <CardDialog isDialog={true} onClose={onClose} maxWidth="md" fullWidth title={title}>
      <div className="w-full">
        <LoadingSwitch isLoading={isLoading} isError={isError}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              await onSaveConfiguration({
                line_of_business: lob,
                sub_org_id: subOrganization.id,
                search_query_options: values.search_query_options,
              });
            }}
          >
            <SubOrgLobFnolConfigurationDialogInner onClose={onClose} />
          </Formik>
        </LoadingSwitch>
      </div>
    </CardDialog>
  );
};

const FieldTypeSelectField: React.FC<{ id: string; label: string }> = ({ id, label }) => {
  const types = ['text', 'number', 'date', 'time', 'datetime', 'year', 'boolean'];
  return (
    <TextFieldFormik id={id} label={label} select fullWidth={true}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {types.map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

interface SubOrgLobFnolConfigurationDialogInnerProps {
  onClose: () => void;
}

const SubOrgLobFnolConfigurationDialogInner: React.FC<SubOrgLobFnolConfigurationDialogInnerProps> = ({ onClose }) => {
  const { isSubmitting, submitForm } = useFormikContext<FnolConfigurationPayload>();

  const arrayFieldConfig = [
    {
      id: 'param_name',
      label: 'Param Name',
      type: 'text',
    },
    {
      id: 'param_description',
      label: 'Param Description',
      type: 'text',
    },
    {
      id: 'param_type',
      label: 'Param type',
      type: 'custom',
      customComponent: FieldTypeSelectField as unknown as React.ReactElement,
    },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <ArrayFieldFormik
          fieldId="search_query_options"
          innerObjectConfig={arrayFieldConfig}
          initialNewItemValues={{
            param_name: '',
            param_description: '',
          }}
          allowDeletingInitialValues
          addNewItemButtonText="Add Search Query Option"
        />
      </Grid>
      <div className="mt-16 flex w-full justify-end">
        <DialogFooterActions
          primaryLabel="Save"
          onClickPrimary={submitForm}
          onClickSecondary={onClose}
          disabled={isSubmitting}
        />
      </div>
    </Grid>
  );
};
