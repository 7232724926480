import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import { Grid } from '@mui/material';
import { getIn, useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import ArrayMultiselectFieldWithChipsFormik from '~/components/core/ArrayMultiselectFieldWithChipsFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';

import { STATES_SELECT_OPTIONS } from '../utils';

import styles from './styles.module.scss';

const IS_ALL_STATES = 'is_all_states';
const STATES = 'states';

export const StatesConfigurationFormik = ({ direction = 'row' }) => {
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  const handleSelectAll = () => {
    setFieldValue(
      STATES,
      STATES_SELECT_OPTIONS.map((option) => option.value)
    );
  };

  const handleClear = () => {
    setFieldValue(STATES, []);
  };

  useFormikChangeListener({
    onChange: (values) => {
      if (getIn(values, IS_ALL_STATES)) {
        setFieldValue('states', []);
      }
    },
    listenForKeys: [IS_ALL_STATES],
  });

  const isAllStates = getIn(values, IS_ALL_STATES);

  return (
    <Grid
      container
      spacing={direction === 'row' ? 2 : 0}
      direction={direction}
      className={styles.statesConfigurationContainer}
      alignItems="space-between"
    >
      <Grid
        item
        xs={direction === 'row' ? 2 : 12}
        className={styles.switchContainer}
        style={{ marginLeft: direction === 'column' ? '-12px' : 'unset' }}
      >
        <SwitchFormik label="All states" id="is_all_states" className={classes.formsSwitch} />
      </Grid>
      <Grid item xs={direction === 'row' ? 10 : 12} direction="row" className={styles.selectContainerGrid}>
        <div className={styles.selectContainer}>
          <ArrayMultiselectFieldWithChipsFormik
            showValues
            disabled={isAllStates}
            label="State"
            id="states"
            options={STATES_SELECT_OPTIONS}
          />
          <div className={styles.selectAllContainer}>
            <ButtonGroup variant="text" size="small">
              <Button
                style={{ minWidth: 'max-content', whiteSpace: 'nowrap' }}
                disabled={isAllStates}
                onClick={handleSelectAll}
              >
                Select All
              </Button>
              <Button disabled={isAllStates} onClick={handleClear}>
                Clear
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

StatesConfigurationFormik.propTypes = {
  direction: PropTypes.string,
};
