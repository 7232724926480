import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import CardDialog from '~/components/CardDialog';
import WithConfirm from '~/components/ConfirmModal';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Switch from '~/components/core/Atomic/Switch';
import Editor from '~/components/core/Editor';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import CopyFileIcon from '~/components/icons/CopyFileIcon';
import EyeIcon from '~/components/icons/EyeIcon';
import PencilIcon from '~/components/icons/PencilIcon';
import TrashIcon from '~/components/icons/TrashIcon';
import InlineIconButton from '~/components/InlineIconButton';
import PlainTable from '~/components/PlainTable';
import EmailAcknowledgmentBuilderContainer from '~/components/SystemConfiguration/EmailConfiguration/EmailAcknowledgmentBuilderContainer';
import EmailConfigurationBuilderContainer from '~/components/SystemConfiguration/EmailConfiguration/EmailConfigurationBuilderContainer';
import EmailConfigurationPreviewContainerCardDialogWrapper from '~/components/SystemConfiguration/EmailConfiguration/EmailConfigurationPreviewContainer';
import type {
  EmailAcknowledgmentSubmitValues,
  EmailConfigurationData,
  EmailConfigurationSubmitValues,
  ExistingEmailConfigurationData,
} from '~/components/SystemConfiguration/EmailConfiguration/emailConfigurationTypes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { serverDateToLocal } from '~/DateTimeUtils';
import colors from '~/theme/tailwind/colors';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError, subOrgIdToNameDict } from '~/Utils';

import Heading from '../../core/TextComponents/Heading';
import AddIcon from '../../icons/AddIcon';

const BASE_PATH = '/api/v1/email_configuration/organizations';

const EmailConfiguration: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const subOrganizationsNames: { [key: number]: string } = subOrgIdToNameDict(organization);
  const subOrganizationIds = Object.keys(subOrganizationsNames).map(Number);

  const [emailConfigurationDataToEdit, setEmailConfigurationDataToEdit] = useState<
    EmailConfigurationData | undefined
  >();
  const [emailConfigurationPreviewData, setEmailConfigurationPreviewData] = useState<
    EmailConfigurationData | undefined
  >();
  const [isAddSubOrg, setIsAddSubOrg] = useState<boolean>(false);
  const [emailTitleEditorKey, setEmailTitleEditorKey] = useState<string>(uuidv4());

  const [isEditEmailAcknowledgment, setIsEditEmailAcknowledgment] = useState<boolean>(false);
  const [isLoadingAcknowledgment, setIsLoadingAcknowledgment] = useState<boolean>(false);

  const {
    data: emailConfigurationTableData,
    reloadData,
    isLoading: isLoadingEmailConfigurationTableData,
    isError: isErrorEmailConfigurationTableData,
  } = useDataFetcher(`${BASE_PATH}/${organization.id}`);

  const emailDomainsUrl = `/api/v1/organizations/${organization.id}/email_domains`;

  const {
    data: emailDomains,
    isLoading: isLoadingEmailDomains,
    isError: isErrorEmailDomains,
  } = useDataFetcher(emailDomainsUrl);

  // Due to Editors' tight coupling with their initial content, direct updates don't trigger re-renders. By updating the component's key via useEffect,
  // we force a rerender to reflect content changes, ensuring the UI stays updated
  useEffect(() => {
    const key = uuidv4();
    setEmailTitleEditorKey(key);
  }, [emailConfigurationTableData]);

  const handleEditConfiguration = (configurationData: EmailConfigurationData) => {
    setEmailConfigurationDataToEdit(configurationData);
  };

  const handleAddSubOrgConfiguration = () => {
    setIsAddSubOrg(true);
  };

  const handleDelete = async (configurationId: number) => {
    try {
      await axios.delete(`${BASE_PATH}/${organization.id}/${configurationId}`);
    } catch (error) {
      await reportAxiosError(error);
    }
    await reloadData();
  };

  const handleCopy = (configurationData: EmailConfigurationData) => {
    setIsAddSubOrg(true);
    setEmailConfigurationDataToEdit({ ...configurationData, id: undefined, sub_organization_id: undefined });
  };

  const handlePreview = (configurationData: EmailConfigurationData) => {
    setEmailConfigurationPreviewData(configurationData);
  };

  const handleClosePreview = () => {
    setEmailConfigurationPreviewData(undefined);
  };

  const handleCloseEditConfigurationDialog = () => {
    setIsAddSubOrg(false);
    setEmailConfigurationDataToEdit(undefined);
  };

  const handleUpdateEmailConfiguration = async (
    values: EmailConfigurationSubmitValues,
    configurationId: number | undefined
  ) => {
    if (!configurationId) return;
    try {
      await axios.patch(`${BASE_PATH}/${organization.id}/${configurationId}`, values);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleEmailAcknowledgmentSubmit = async (
    values: EmailAcknowledgmentSubmitValues,
    acknowledgmentId?: number
  ) => {
    const method = acknowledgmentId ? 'patch' : 'post';
    const requestData = method === 'post' ? values : { ...values, is_enabled: isAcknowledgmentEnabled };

    setIsLoadingAcknowledgment(true);
    try {
      await axios[method](`${BASE_PATH}/${organization.id}/auto_acknowledgment_configuration`, requestData);
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsLoadingAcknowledgment(false);
    }
  };

  const handleAddEmailConfiguration = async (values: EmailConfigurationSubmitValues) => {
    try {
      await axios.post(`${BASE_PATH}/${organization.id}`, values);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const openAcknowledgmentEditor = () => {
    setIsEditEmailAcknowledgment(true);
  };

  const handleEnabledAcknowledgmentChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await axios.patch(`${BASE_PATH}/${organization.id}/auto_acknowledgment_configuration`, {
        ...emailConfigurationTableData?.email_acknowledgment_configuration,
        is_enabled: event.target.checked,
      });
      await reloadData();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleCloseEditAcknowledgmentDialog = () => {
    setIsEditEmailAcknowledgment(false);
  };

  const emailConfigurationsColumns = [
    {
      id: 'edited_by_username',
      label: 'Edited By',
    },
    {
      id: 'edit_date',
      label: 'Edit Date',
      specialCell: (rowData: EmailConfigurationData) => serverDateToLocal(rowData.edited_at),
    },
    {
      id: 'email_default_title',
      label: 'Default Email Subject',
      specialCell: ({ email_default_title }: Pick<EmailConfigurationData, 'email_default_title'>) => {
        return (
          <Editor key={emailTitleEditorKey} disabled content={email_default_title} containerClassName="border-0" />
        );
      },
    },
    {
      id: 'email_signature',
      label: 'Email Signature',
      specialCell: (rowData: EmailConfigurationData) => {
        return (
          <Button color="primary" disabled={false} onClick={() => handlePreview(rowData)}>
            <EyeIcon iconColor={colors.teal['700']} className="mr-4" />
            Generate example
          </Button>
        );
      },
    },
  ];

  const getRelevantDefaultEmailConfigurationsColumns = () => {
    return organization.is_suborgs_domains_enabled
      ? {
          id: 'emailDomain',
          label: 'Email Domain',
          specialCell: (rowData: EmailConfigurationData) => {
            if (emailDomains) {
              return emailDomains.find(
                (domain: { id: number; domain: string }) => domain.id === rowData.email_domain_id
              )?.domain;
            }
          },
        }
      : {
          id: 'organization',
          label: 'Organization',
          specialCell: () => organization.name,
        };
  };

  const defaultEmailConfigurationsColumns = [
    getRelevantDefaultEmailConfigurationsColumns(),
    ...emailConfigurationsColumns,
    {
      id: 'actions',
      label: 'Actions',
      specialCell: (rowConfigurationData: ExistingEmailConfigurationData) => {
        return (
          <InlineIconButton
            icon={PencilIcon}
            onClick={() => handleEditConfiguration(rowConfigurationData)}
            tooltipTitle="Edit"
            defaultColor={undefined}
            disabled={undefined}
            className="m-4 hover:text-teal-700"
            iconColor="currentColor"
            iconStyle={undefined}
            useIconButton={undefined}
            wrapWithSpan={undefined}
            ignorePermissions={undefined}
            ariaLabel={undefined}
          />
        );
      },
    },
  ];

  const subOrgEmailConfigurationsColumns = [
    {
      id: 'sub_organization_id',
      label: 'Sub-Org',
      specialCell: (rowData: EmailConfigurationData) =>
        rowData.sub_organization_id ? subOrganizationsNames[rowData.sub_organization_id] : null,
    },
    ...emailConfigurationsColumns,
    {
      id: 'actions',
      label: 'Actions',
      specialCell: (rowConfigurationData: ExistingEmailConfigurationData) => {
        const subOrgName = rowConfigurationData.sub_organization_id
          ? subOrganizationsNames[rowConfigurationData.sub_organization_id]
          : null;
        return (
          <div>
            <InlineIconButton
              className="m-4 hover:text-teal-700"
              iconColor="currentColor"
              icon={PencilIcon}
              onClick={() => handleEditConfiguration(rowConfigurationData)}
              tooltipTitle="Edit"
              defaultColor={undefined}
              disabled={undefined}
              iconStyle={undefined}
              useIconButton={undefined}
              wrapWithSpan={undefined}
              ignorePermissions={undefined}
              ariaLabel={undefined}
            />
            <WithConfirm
              title="Delete sub organization configuration?"
              contentText={`Are you sure you want to delete ${subOrgName} email configuration? This will cause emails sent to use the default organization level configuration`}
              primaryButtonName="Delete"
              postOnClick={async () => await reloadData()}
            >
              <InlineIconButton
                className="m-4 hover:text-teal-700"
                iconColor="currentColor"
                icon={TrashIcon}
                onClick={() => handleDelete(rowConfigurationData.id)}
                tooltipTitle="Delete"
                iconStyle={undefined}
                useIconButton={undefined}
                wrapWithSpan={undefined}
                ariaLabel={undefined}
                defaultColor={undefined}
                disabled={undefined}
                ignorePermissions={undefined}
              />
            </WithConfirm>
            <InlineIconButton
              className="m-4 hover:text-teal-700"
              iconColor="currentColor"
              icon={CopyFileIcon}
              onClick={() => handleCopy(rowConfigurationData)}
              tooltipTitle="Copy"
              defaultColor={undefined}
              disabled={hasEmailConfigForAllSubOrgs}
              iconStyle={undefined}
              useIconButton={undefined}
              wrapWithSpan={undefined}
              ignorePermissions={undefined}
              ariaLabel={undefined}
            />
          </div>
        );
      },
    },
  ];

  const emailSignatureAndTitleConfiguration = emailConfigurationTableData?.email_signature_and_title_configurations;

  const isAcknowledgmentEnabled = emailConfigurationTableData?.email_acknowledgment_configuration?.is_enabled;

  const defaultEmailConfigurationsData: EmailConfigurationData[] = emailSignatureAndTitleConfiguration?.filter(
    (row: EmailConfigurationData) => !row.sub_organization_id
  );

  const subOrgsEmailConfigurationData: EmailConfigurationData[] = emailSignatureAndTitleConfiguration?.filter(
    (row: EmailConfigurationData) => row.sub_organization_id
  );

  const subOrgsWithEmailConfigurationIds: number[] =
    emailSignatureAndTitleConfiguration
      ?.filter((row: EmailConfigurationData) => row.sub_organization_id)
      .map((subOrgConfig: EmailConfigurationData) => subOrgConfig.sub_organization_id) || [];

  const hasEmailConfigForAllSubOrgs =
    subOrgsWithEmailConfigurationIds.length === Object.keys(subOrganizationsNames).length;

  return (
    <div className="bg-slate-100 p-4">
      <div className="m-20">
        <CardDialog title="Default Email Configuration">
          {isLoadingEmailConfigurationTableData || isLoadingEmailDomains ? (
            <SkeletonTable
              rowsCount={1}
              columnsCount={6}
              isError={isErrorEmailConfigurationTableData || isErrorEmailDomains}
            />
          ) : (
            <PlainTable columns={defaultEmailConfigurationsColumns} rows={defaultEmailConfigurationsData} />
          )}
        </CardDialog>
      </div>
      {organization.sub_organizations_enabled && (
        <div className="m-20">
          <CardDialog
            title="Sub-Org Email Configuration"
            action={
              <div className="p-4">
                <Button
                  color="primary"
                  disabled={hasEmailConfigForAllSubOrgs}
                  onClick={handleAddSubOrgConfiguration}
                  startIcon={<AddIcon iconColor="currentColor" />}
                >
                  Add signature
                </Button>
              </div>
            }
          >
            {isLoadingEmailConfigurationTableData ? (
              <SkeletonTable rowsCount={3} columnsCount={6} isError={isErrorEmailConfigurationTableData} />
            ) : (
              <>
                <div className="pb-20">
                  <AlertBanner
                    alertType={AlertBanner.ALERT_TYPES.WARNING}
                    note={`Please note: Emails which are not attached to a claim (i.e. not attached to a sub-organization) will present the default ${
                      organization.is_suborgs_domains_enabled ? 'domain' : 'organization'
                    } signature`}
                    withIcon
                  />
                </div>
                <PlainTable columns={subOrgEmailConfigurationsColumns} rows={subOrgsEmailConfigurationData} />
              </>
            )}
          </CardDialog>
        </div>
      )}
      {isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.EMAIL_AUTO_ACKNOWLEDGMENT_CONFIGURATION) ? (
        isLoadingEmailConfigurationTableData ? (
          <SkeletonTable rowsCount={1} columnsCount={1} isError={isErrorEmailConfigurationTableData} />
        ) : (
          <div className="m-20">
            <div className="flex items-center justify-between rounded-lg bg-white p-4">
              <div className="w-full p-24">
                <Heading variant={Heading.TYPES.H2} className="mb-20">
                  Acknowledgment Email
                </Heading>
              </div>
              <div className="px-24">
                {emailConfigurationTableData?.email_acknowledgment_configuration ? (
                  <div className="flex gap-8">
                    <Switch
                      onChange={(event) => handleEnabledAcknowledgmentChange(event)}
                      disabled={isLoadingAcknowledgment}
                      checked={isAcknowledgmentEnabled}
                    />
                    <InlineIconButton
                      className="m-4 hover:text-teal-700"
                      iconColor="currentColor"
                      icon={PencilIcon}
                      onClick={openAcknowledgmentEditor}
                      tooltipTitle="Edit"
                      disabled={!isAcknowledgmentEnabled || isLoadingAcknowledgment}
                    />
                  </div>
                ) : (
                  <Button
                    color="primary"
                    disabled={false}
                    onClick={openAcknowledgmentEditor}
                    fullWidth={true}
                    startIcon={<AddIcon iconColor="currentColor" />}
                    className="whitespace-nowrap"
                  >
                    Add template
                  </Button>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
      {emailConfigurationDataToEdit || isAddSubOrg ? (
        <EmailConfigurationBuilderContainer
          reloadTable={reloadData}
          handleConfigSubmit={isAddSubOrg ? handleAddEmailConfiguration : handleUpdateEmailConfiguration}
          handleClose={handleCloseEditConfigurationDialog}
          emailConfigurationData={emailConfigurationDataToEdit}
          isAddSubOrg={isAddSubOrg}
          excludedSubOrgsIdsForSelect={subOrgsWithEmailConfigurationIds}
        />
      ) : null}
      {emailConfigurationPreviewData ? (
        <EmailConfigurationPreviewContainerCardDialogWrapper
          onClose={handleClosePreview}
          emailConfiguration={emailConfigurationPreviewData}
          organizationSubOrgIds={subOrganizationIds}
        />
      ) : null}
      {isEditEmailAcknowledgment ? (
        <EmailAcknowledgmentBuilderContainer
          handleClose={handleCloseEditAcknowledgmentDialog}
          handleEmailAcknowledgmentSubmit={handleEmailAcknowledgmentSubmit}
          reloadTable={reloadData}
          emailAcknowledgmentData={emailConfigurationTableData?.email_acknowledgment_configuration}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailConfiguration;
