import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import type { SuggestionOption } from '~/components/core/Editor/types';
import EmailConfigurationEditor from '~/components/SystemConfiguration/EmailConfiguration/EmailConfigurationEditor';
import type {
  EmailConfigurationBuilderContainerProps,
  EmailConfigurationSubmitValues,
  EmailConfigurationTokenOptions,
} from '~/components/SystemConfiguration/EmailConfiguration/emailConfigurationTypes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { SuggestionToken } from '~/components/SystemConfiguration/SystenConfigurationTypes';
import SubOrganizationSelectFormik from '~/components/TPA/SubOrganizations/SubOrganizationSelectFormik';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

const EMAIL_CONFIGURATION_TOKENS = {
  emailTitleTokens: ['CLAIM_ID_DISPLAY', 'SUB_ORGANIZATION_NAME', 'ORGANIZATION_NAME'],
  claimEmailAddressToken: ['CLAIM_EMAIL_OR_USER_EMAIL_FALLBACK'],
  emailSignatureTokens: [
    'USER_FULL_NAME',
    'USER_TITLE',
    'USER_EMAIL',
    'USER_PHONE_NUMBER',
    'SUB_ORGANIZATION_NAME',
    'ORGANIZATION_NAME',
  ],
};
const noSubOrgEmailTitleTokens = EMAIL_CONFIGURATION_TOKENS.emailTitleTokens.filter(
  (token: string) => token !== 'SUB_ORGANIZATION_NAME'
);

const noSubOrgEmailSignatureTokens = EMAIL_CONFIGURATION_TOKENS.emailSignatureTokens.filter(
  (token: string) => token !== 'SUB_ORGANIZATION_NAME'
);

const useTokensFetcher = (organizationId: string, tokensSubset: string[]) => {
  return useDataFetcher(`/api/v1/generic_templates/organization/${organizationId}/template/tokens?`, {
    params: {
      tokens_subset: tokensSubset,
      feature_specific: 'email_configuration',
    },
  });
};

export const getRelevantFieldTitle = (isSubOrg: boolean, configurationFieldName: string): string =>
  `${isSubOrg ? 'Sub-Org' : 'Default'} ${configurationFieldName}`;

const EmailConfigurationBuilderContainer: React.FC<EmailConfigurationBuilderContainerProps> = ({
  handleClose,
  handleConfigSubmit,
  reloadTable,
  emailConfigurationData,
  isAddSubOrg,
  excludedSubOrgsIdsForSelect,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const isEditSubOrgEmailConfiguration = !!emailConfigurationData?.sub_organization_id;
  const isSubOrganization = isEditSubOrgEmailConfiguration || isAddSubOrg;

  const relevantEmailTitleTokens = isSubOrganization
    ? EMAIL_CONFIGURATION_TOKENS.emailTitleTokens
    : noSubOrgEmailTitleTokens;

  const getRelevantSignatureTokens = () => {
    const subOrgOrNoSubOrgTokens = isSubOrganization
      ? EMAIL_CONFIGURATION_TOKENS.emailSignatureTokens
      : noSubOrgEmailSignatureTokens;

    const claimInboxTokensIfEnabled = organization.is_claims_inbox_enabled
      ? EMAIL_CONFIGURATION_TOKENS.claimEmailAddressToken
      : [];

    return [...subOrgOrNoSubOrgTokens, ...claimInboxTokensIfEnabled];
  };

  const relevantSignatureTokens = getRelevantSignatureTokens();

  const { data: titleTokensData, isLoading: isLoadingTitleTokens } = useTokensFetcher(
    organization.id,
    relevantEmailTitleTokens
  );

  const { data: signatureTokensData, isLoading: isLoadingSignatureTokens } = useTokensFetcher(
    organization.id,
    relevantSignatureTokens
  );

  const mapTokens = (tokensData: { tokens: SuggestionToken[] }) => {
    return (
      tokensData?.tokens?.map((token: SuggestionToken) => ({
        label: token.display_name,
        id: token.token_key,
      })) || []
    );
  };

  const emailTitleTokens: SuggestionOption[] = !isLoadingTitleTokens ? mapTokens(titleTokensData) : [];
  const emailSignatureTokens: SuggestionOption[] = !isLoadingSignatureTokens ? mapTokens(signatureTokensData) : [];

  const emailConfigurationTokens: EmailConfigurationTokenOptions = { emailTitleTokens, emailSignatureTokens };

  return (
    <CardDialog
      isDialog
      title={getRelevantFieldTitle(isSubOrganization, 'Email Configuration')}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Formik
        initialValues={{
          sub_organization_id: emailConfigurationData?.sub_organization_id,
          email_default_title: emailConfigurationData?.email_default_title,
          email_signature_html_template: emailConfigurationData?.email_signature_html_template,
        }}
        validationSchema={Yup.object().shape({
          sub_organization_id: isSubOrganization
            ? Yup.number().nullable().required('Required')
            : Yup.number().nullable(),
        })}
        onSubmit={async (values: EmailConfigurationSubmitValues, { setSubmitting }) => {
          try {
            await handleConfigSubmit(values, emailConfigurationData?.id);
            reloadTable();
            handleClose();
          } catch (error) {
            await reportAxiosError(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => {
          const handleChange = (value: string, id: string) => {
            setFieldValue(id, value);
          };
          return (
            <>
              {isSubOrganization ? (
                <div className="col-span-2 m-4">
                  <SubOrganizationSelectFormik
                    fieldId="sub_organization_id"
                    disabled={isSubmitting || !isAddSubOrg}
                    excludedSubOrganizationsIds={isAddSubOrg ? excludedSubOrgsIdsForSelect : []}
                  />
                </div>
              ) : null}
              <EmailConfigurationEditor
                handleChange={handleChange}
                currentEmailSubject={emailConfigurationData?.email_default_title}
                currentEmailSignature={emailConfigurationData?.email_signature_html_template}
                suggestions={emailConfigurationTokens}
                isSubOrg={isSubOrganization}
                isLoadingTokens={isLoadingTitleTokens || isLoadingSignatureTokens}
              />
              <div className="mt-16 flex w-full justify-end">
                <DialogFooterActions
                  primaryLabel="Save"
                  onClickPrimary={handleSubmit}
                  onClickSecondary={handleClose}
                  disabled={isSubmitting}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </CardDialog>
  );
};

export default EmailConfigurationBuilderContainer;
