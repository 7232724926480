import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, isEmpty, snakeCase } from 'lodash';

import { useStyles } from '~/assets/styles';
import CheckboxFormik from '~/components/CheckboxFormik';
import WithConfirm from '~/components/ConfirmModal';
import ArrayMultiselectFieldWithChipsFormik from '~/components/core/ArrayMultiselectFieldWithChipsFormik';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import FsTooltip from '~/components/core/FsWrappers/FsTooltip/FsTooltip';
import TextField from '~/components/core/Molecules/Fields/TextField';
import TooltipIcon from '~/components/core/TooltipIcon';
import { InfoIcon } from '~/components/icons';
import useOrganization from '~/components/OrganizationContext';
import {
  CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY,
  useCliveConfigOptions,
} from '~/components/SystemConfiguration/CliveConfiguration/CliveConfigurationContext';
import {
  CONTACT_API_SUFFIX,
  CONTACT_CONFIG_SUFFIX,
} from '~/components/SystemConfiguration/FnolConfiguration/constants';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';
import { isLocaleRegionIsUK, isLocaleRegionIsUs } from '~/Utils/regionUtils';

import copies from '../copies.json';
import HiddenIndication from '../HiddenIndication/HiddenIndication';
import OptionsCard from '../OptionsCard/OptionsCard';

import ConfigurationFieldType from './ConfigurationFieldType';

const isFieldGeoSpecificNotAllowed = (geoSpecific) => {
  if (!geoSpecific) return false;

  if (geoSpecific === 'US' && !isLocaleRegionIsUs()) {
    return true;
  }

  if (geoSpecific === 'GB' && !isLocaleRegionIsUK()) {
    return true;
  }
};

const getActions = ({ isActive = false, disabledCollapse = false, actions = undefined }) => (
  <div>
    <HiddenIndication isActive={isActive && !disabledCollapse} />
    {actions}
  </div>
);

const getDisplayKey = (values, id, isConfiguredField, fieldId) => {
  const displayKey = isConfiguredField ? get(values, `${id}.id`, '') : fieldId;
  return displayKey.endsWith(CONTACT_CONFIG_SUFFIX)
    ? displayKey.replace(CONTACT_CONFIG_SUFFIX, CONTACT_API_SUFFIX)
    : displayKey;
};

const FieldConfigurationCard = ({
  idPrefix,
  fieldId,
  forceRequired = false,
  isConfiguredField = false,
  disabledCollapse = false,
  actions = undefined,
  forceDisabled = false,
  infoTooltipText,
  backgroundColor = CollapsibleWrapper.BACKGROUND_COLOR.LIST,
  disableCategories = false,
  disableAdd = false,
  withoutRequiredForClaimClosing = false,
  requiredLabel,
}) => {
  const classes = useStyles();
  const { values, setFieldValue, initialValues, touched } = useFormikContext();
  const { organizationContactRolesDict } = useOrganization();
  const id = idPrefix ? `${idPrefix}.${fieldId}` : fieldId;
  const field = get(values, id, {});

  const isNewField = isEmpty(get(initialValues, id, {}));
  const wasRequired = get(initialValues, `${id}.mandatory`, false);
  const isRequired = get(values, `${id}.mandatory`, false);
  const isRequiredBeforeClose = get(values, `${id}.claim_closing_mandatory`, false);
  const isActive = get(values, `${id}.active`, false);
  const geoSpecific = get(values, `${id}.geo_specific`);
  const isOptionsField = ['select', 'multiselect'].includes(get(values, `${id}.type`, ''));
  const isContactField = ['contact'].includes(get(values, `${id}.type`, ''));
  const categories = get(values, 'configuration.categories', [])?.map((category) => ({
    id: category.id,
    value: category.id,
    label: category.desc,
  }));
  const contactRoles = Object.keys(organizationContactRolesDict)?.map((role) => ({
    id: role,
    value: role,
    label: organizationContactRolesDict[role]?.desc,
  }));
  const isNewConfiguredField = isNewField && isConfiguredField;
  const wasKeyTouched = get(touched, `${id}.id`);
  const displayKey = getDisplayKey(values, id, isConfiguredField, fieldId);
  const isDynamicField = get(values, `${id}.dynamic`, false);
  const title = !infoTooltipText ? (
    field.desc
  ) : (
    <div className="flex items-center">
      <div className="mr-12">{field.desc}</div>
      <TooltipIcon title={infoTooltipText} arrow>
        <InfoIcon size={20} />
      </TooltipIcon>
    </div>
  );

  const { showCliveConfigOptions } = useCliveConfigOptions();

  useSetDefaultFieldsOnChange(
    get(values, `${id}.desc`),
    {
      [`${id}.id`]: snakeCase(get(values, `${id}.desc`)),
    },
    null,
    !isNewConfiguredField || wasKeyTouched
  );

  useEffect(() => {
    if (forceRequired) {
      setFieldValue(`${id}.active`, true);
      setFieldValue(`${id}.mandatory`, true);
    }
  }, [forceRequired, id, setFieldValue]);

  if (isFieldGeoSpecificNotAllowed(geoSpecific)) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <CollapsibleWrapper
        title={title}
        subtitle={`Key: ${displayKey}`}
        backgroundColor={backgroundColor}
        actionCard
        noBorderLine
        noDivider
        actions={getActions({ isActive, disabledCollapse, actions })}
        disabled={disabledCollapse}
        withActionsContainerFullWidth={false}
        defaultState={isNewConfiguredField}
      >
        <InnerCard>
          <Grid container className="flex items-center" spacing={3}>
            <Grid item xs={6}>
              <TextFieldFormik id={`${id}.desc`} label="Display Name" fullWidth disabled={forceDisabled || !isActive} />
            </Grid>
            <Grid item xs={6}>
              {isNewConfiguredField ? (
                <TextFieldFormik id={`${id}.id`} label="Key" fullWidth />
              ) : (
                <TextField label="Key" value={displayKey} fullWidth disabled />
              )}
            </Grid>
            <Grid item xs={6}>
              <ConfigurationFieldType id={id} isConfiguredField={isConfiguredField} />
            </Grid>
            <Grid item xs={6}>
              <ArrayMultiselectFieldWithChipsFormik
                id={`${id}.incident_sub_type_categories`}
                label="Limit to Categories"
                options={categories}
                disabled={
                  forceRequired ||
                  forceDisabled ||
                  isDynamicField ||
                  !isActive ||
                  isEmpty(categories) ||
                  disableCategories
                }
              />
            </Grid>
            {isContactField && (
              <>
                <Grid item xs={6}>
                  <ArrayMultiselectFieldWithChipsFormik
                    id={`${id}.accepted_roles`}
                    label="Limit to Contact Roles"
                    options={contactRoles}
                    disabled={
                      forceRequired ||
                      forceDisabled ||
                      isDynamicField ||
                      !isActive ||
                      isEmpty(contactRoles) ||
                      !isNewConfiguredField
                    }
                  />
                </Grid>
                <Grid item xs={6} />
              </>
            )}
            <Grid item xs={12} className="MuiGrid-item !pl-0">
              {isActive && (isRequired || isRequiredBeforeClose) && !(forceRequired || forceDisabled) ? (
                <FsTooltip arrow title="Required Field cannot be hidden">
                  <SwitchFormik
                    id={`${id}.active`}
                    label="Show"
                    className={classes.formsSwitch}
                    hideErrorGap
                    disabled={forceRequired || forceDisabled || isRequired || isRequiredBeforeClose}
                  />
                </FsTooltip>
              ) : (
                <SwitchFormik
                  id={`${id}.active`}
                  label="Show"
                  className={classes.formsSwitch}
                  hideErrorGap
                  disabled={forceRequired || forceDisabled || isRequired || isRequiredBeforeClose}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <WithConfirm
                title={copies.change_field_required_confirm.title}
                contentText={copies.change_field_required_confirm.text}
                primaryButtonName="YES, PROCEED"
                shouldCloseOnPrimary
                triggerMethod="onChange"
                centerDialog
                disableConfirm={isRequired || wasRequired}
              >
                <CheckboxFormik
                  id={`${id}.mandatory`}
                  label={requiredLabel || 'Required field for claim submission'}
                  disabled={forceRequired || forceDisabled || isDynamicField || !isActive}
                  onChange={() => setFieldValue(`${id}.mandatory`, !get(values, `${id}.mandatory`, false))}
                />
              </WithConfirm>
            </Grid>
            {isConfiguredField && !withoutRequiredForClaimClosing ? (
              <Grid item xs={6}>
                <CheckboxFormik
                  id={`${id}.claim_closing_mandatory`}
                  label="Required field when claim closed"
                  disabled={!isActive}
                  onChange={() =>
                    setFieldValue(`${id}.claim_closing_mandatory`, !get(values, `${id}.claim_closing_mandatory`, false))
                  }
                />
              </Grid>
            ) : null}
            {isOptionsField && !isDynamicField && (
              <OptionsCard idPrefix={idPrefix} fieldId={fieldId} disableAdd={disableAdd} />
            )}
            {showCliveConfigOptions ? (
              <Grid item xs={12}>
                <TextFieldFormik
                  id={`${id}.${CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY}`}
                  label="Clive additional instructions"
                  fullWidth
                  multiline
                  rows={1}
                />
              </Grid>
            ) : null}
          </Grid>
        </InnerCard>
      </CollapsibleWrapper>
    </Grid>
  );
};

FieldConfigurationCard.propTypes = {
  idPrefix: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  savedValues: PropTypes.object,
  forceRequired: PropTypes.bool,
  isConfiguredField: PropTypes.bool,
  disabledCollapse: PropTypes.bool,
  actions: PropTypes.node,
  forceDisabled: PropTypes.bool,
  infoTooltipText: PropTypes.string,
  backgroundColor: PropTypes.oneOf(Object.values(CollapsibleWrapper.BACKGROUND_COLOR)),
  disableCategories: PropTypes.bool,
  disableAdd: PropTypes.bool,
  withoutRequiredForClaimClosing: PropTypes.bool,
  requiredLabel: PropTypes.string,
};

FieldConfigurationCard.BACKGROUND_COLOR = CollapsibleWrapper.BACKGROUND_COLOR;

export default FieldConfigurationCard;
