import React from 'react';

import CardDialog from '~/components/CardDialog';
import type { InnerTabSingleProps } from '~/components/core/Layout/InnerTabs/InnerTabs';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import type {
  GenericTemplatesAdditionalPropsType,
  GenericTemplatesTableContainerPropsType,
} from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesTableContainer';
import GenericTemplatesTableContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesTableContainer';
import type { NotificationTemplateFormSubmitValuesProps } from '~/components/GenericTemplates/types';
import { getNotificationPriorityOrDefault } from '~/components/Notifications/utils';
import type { NotificationConfigurationFieldsFormikInnerProps } from '~/components/SystemConfiguration/NotificationUtils/NotificationConfigurationFieldsFormikInner';
import NotificationConfigurationFieldsFormikInner from '~/components/SystemConfiguration/NotificationUtils/NotificationConfigurationFieldsFormikInner';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import { DOCUMENT_TEMPLATES_ROUTES } from '../utils/routes';

const GenericTemplatesConfigurationScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const isNotifications2Enabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);
  const notificationFields: GenericTemplatesAdditionalPropsType<NotificationConfigurationFieldsFormikInnerProps> = {
    component: NotificationConfigurationFieldsFormikInner,
    props: { isDueDateImmediateField: 'is_due_date_immediate' },
    defaultFormValues: {
      priority: getNotificationPriorityOrDefault(),
      is_due_date_immediate: true,
    } as NotificationTemplateFormSubmitValuesProps,
  };

  const tabs: InnerTabSingleProps<GenericTemplatesTableContainerPropsType>[] = [
    {
      label: 'Documents',
      url: 'document_templates',
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'document',
        isCreateTemplateEnabled: false, // TODO: Enable this in NGTPA-17180
        shouldDisplayType: false,
        templateRoutesDict: DOCUMENT_TEMPLATES_ROUTES,
      },
      isTabHidden: !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.EXTERNALIZE_DOCUMENT_TEMPLATES),
    },
    {
      label: 'Email',
      url: 'email_templates',
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'email',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
      },
    },
    {
      label: 'Notes',
      url: 'notes_templates',
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'note',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
      },
    },
    {
      label: 'Notifications',
      url: 'notification_templates',
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'notification',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
        additionalProps: isNotifications2Enabled ? notificationFields : undefined,
      },
    },
    {
      label: 'SMS',
      url: 'sms_templates',
      component: GenericTemplatesTableContainer,
      props: {
        templateType: 'sms',
        isCreateTemplateEnabled: true,
        shouldDisplayType: false,
      },
    },
  ];

  const activeTabs = tabs.filter((tab) => !tab.isTabHidden);

  return (
    <div className="m-20">
      <OperationsBreadcrumbs currentTab="Templates" />
      <CardDialog title="Templates">
        <InnerTabs tabs={activeTabs} />
      </CardDialog>
    </div>
  );
};

export default GenericTemplatesConfigurationScreen;
