const SANCTION_IO_SANCTION_LISTS = [
  {
    country: 'International',
    short_name: 'UN',
    long_name: 'Global / UN - United Nations Sanctions List',
  },
  {
    country: 'International',
    short_name: 'ADB',
    long_name: 'ADB - Asian Development Bank Sanctions List',
  },
  {
    country: 'International',
    short_name: 'EBRD-IE',
    long_name: 'EBRD / Ineligible entities',
  },
  {
    country: 'International',
    short_name: 'PEP',
    long_name: 'Politically Exposed Persons',
  },
  {
    country: 'International',
    short_name: 'FBI',
    long_name: 'Global / FBI - FBI Most Wanted',
  },
  {
    country: 'International',
    short_name: 'INTERPOL',
    long_name: 'Global / INTERPOL - Interpol Red Notices',
  },
  {
    country: 'Argentina',
    short_name: 'AR-MJUS',
    long_name: 'AR / MJUS - Ministerio de Justicia, RePET',
  },
  {
    country: 'Australia',
    short_name: 'AUL',
    long_name: 'AU / Australian Sanctions List',
  },
  {
    country: 'Belgium',
    short_name: 'BEL',
    long_name: 'BE / Belgium Sanctions List',
  },
  {
    country: 'Brazil',
    short_name: 'BR-BCB',
    long_name: 'BR / BCB Register of persons disqualified from senior roles',
  },
  {
    country: 'Brazil',
    short_name: 'BR-BCBAS',
    long_name: 'BR / BCBAS Register of Entities Prohibited from Offering Auditing Services',
  },
  {
    country: 'Brazil',
    short_name: 'BR-FGEIS',
    long_name: 'BR / FG - Empresas Inidôneas e Suspensas',
  },
  {
    country: 'Brazil',
    short_name: 'BR-TCUIDPS',
    long_name: 'BR / TCU list of individuals disqualified from public service',
  },
  {
    country: 'Brazil',
    short_name: 'BR-TCUDB',
    long_name: 'BR / TCU list of debarred bidders',
  },
  {
    country: 'Canada',
    short_name: 'CANADA-SEMA',
    long_name: 'CA / Special Economic Measures Act',
  },
  {
    country: 'Canada',
    short_name: 'CA-JFVCFO',
    long_name: 'CA / Justice for victims of corrupt foreign officials',
  },
  {
    country: 'EU',
    short_name: 'CFSP',
    long_name: 'EU / EU Consolidated List of Sanctions',
  },
  {
    country: 'France',
    short_name: 'FR-GELS',
    long_name: 'FR / Ministry of Economy, Finance and Recovery France - Asset Freezes',
  },
  {
    country: 'India',
    short_name: 'IN-TERRORIST',
    long_name: 'IN / Ministry of Home Affairs - Terrorist Organizations',
  },
  {
    country: 'India',
    short_name: 'IN-UNLAWFUL',
    long_name: 'IN / Ministry of Home Affairs - Unlawful Associations',
  },
  {
    country: 'Israel',
    short_name: 'IL-NBCTF',
    long_name: 'IL / NBCTF - National Bureau for Counter Terror Financing of Israel',
  },
  {
    country: 'Malaysia',
    short_name: 'MY-MHA-MHAL',
    long_name: 'MY / MHA - Ministry of Home Affairs List',
  },
  {
    country: 'Morocco',
    short_name: 'CNASNU',
    long_name: 'MA / CNASNU Local List',
  },
  {
    country: 'Netherlands',
    short_name: 'NL-NSLT',
    long_name: 'NL / National Sanctions List Terrorism',
  },
  {
    country: 'New Zealand',
    short_name: 'ZA-FAT',
    long_name: 'ZA / FAT - Ministry of Foreign Affairs and Trade',
  },
  {
    country: 'Nigeria',
    short_name: 'NG-NIGSAC',
    long_name: 'NG-NIGSAC - Nigeria Sanctions Committee',
  },
  {
    country: 'Poland',
    short_name: 'PL-LPESS',
    long_name: 'PL / List of persons and entities subject to sanctions',
  },
  {
    country: 'Singapore',
    short_name: 'SG-TSFA',
    long_name: 'SG / Terrorism (Suppression of Financing) Act 2002',
  },
  {
    country: 'Singapore',
    short_name: 'SG-MASEA',
    long_name: 'SG / MAS Enforcement Actions',
  },
  {
    country: 'South Africa',
    short_name: 'ZA-OCPO',
    long_name: 'ZA / OCPO - Chief Procurement Office - National Treasury Department',
  },
  {
    country: 'South Africa',
    short_name: 'ZA-FICTFS',
    long_name: 'ZA / FIC - Targeted Financial Sanctions',
  },
  {
    country: 'South Africa',
    short_name: 'ZA-FSCAEA',
    long_name: 'ZA / FSCA - Enforcement Actions',
  },
  {
    country: 'South Africa',
    short_name: 'ZA-CTCC',
    long_name: 'ZA / CT - Current Cases',
  },
  {
    country: 'Switzerland',
    short_name: 'SWISS-SECO',
    long_name: 'CH / Swiss - SECO State Secretariat for Economic Affairs',
  },
  {
    country: 'UAE',
    short_name: 'UAE',
    long_name: 'UAE / IEC sanctions',
  },
  {
    country: 'Ukraine',
    short_name: 'UA-NSDC',
    long_name: 'UA - NSDC State Register of Sanctions, Individuals',
  },
  {
    country: 'Ukraine',
    short_name: 'UA-NSDC',
    long_name: 'UA - NSDC State Register of Sanctions, Entities',
  },
  {
    country: 'United Kingdom',
    short_name: 'HM TREASURY',
    long_name: 'UK / HM Treasury - UK Sanctions List',
  },
  {
    country: 'United States',
    short_name: 'SDN',
    long_name: 'US / OFAC - SDN Specially Designated Nationals',
  },
  {
    country: 'United States',
    short_name: 'NONSDN',
    long_name: 'US / OFAC - Non-SDN Consolidated Sanctions List',
  },
  {
    country: 'United States',
    short_name: 'EL',
    long_name: 'US / BIS - EL Entity List',
  },
] as const;

export default SANCTION_IO_SANCTION_LISTS;
