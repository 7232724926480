import React from 'react';

import AlertBanner from '~/components/core/AlertBanner';

const DOCUMENTATION_LINK =
  'https://docs.fivesigmalabs.com/90QdzjlkkzeSKsiP9PE0/getting-started/system-configuration/system-operations/creating-document-templates/list-of-tokens-by-lob';

const DocumentTemplatesInfoBanner: React.FC = () => {
  return (
    <AlertBanner
      className="mb-12 mt-20"
      alertType={AlertBanner.ALERT_TYPES.INFO}
      withIcon
      title="Please Note"
      note={
        <span>
          Document templates can be customized using tokens anywhere you want to pre-populate information. For a
          complete list of available tokens, continue reading
          <a href={DOCUMENTATION_LINK} target="_blank" rel="noopener noreferrer" className="pl-4">
            here
          </a>
          .
        </span>
      }
    />
  );
};

export default DocumentTemplatesInfoBanner;
