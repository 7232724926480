import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import CardDialog from '~/components/CardDialog';
import GenericTemplateCardButtonsContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateCardButtonsContainer';
import GenericTemplateContentContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateContentContainer';
import DocumentTemplatePreviewContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplatePreview/DocumentTemplatePreviewContainer';
import type { TemplateMetadata } from '~/components/GenericTemplates/types';
import useOrganization from '~/components/OrganizationContext';
import { UpdateDocumentTemplateFormik } from '~/components/SystemConfiguration/OrganizationDocumentsTemplates/OrganizationDocumentsTemplates';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';

interface DocumentTemplateCardProps {
  templateId: number;
  reloadTable: () => Promise<void>;
  templatesMetadata?: TemplateMetadata[];
}

const DocumentTemplateCardContainer: React.FC<DocumentTemplateCardProps> = ({
  templateId,
  reloadTable,
  templatesMetadata = [],
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { documentTypesDict } = useOrganization() as { documentTypesDict: Record<string, { desc: string }> };
  const [isEditTemplateDialogOpen, setIsEditTemplateDialogOpen] = useState(false);
  const [isPreviewTemplateDialogOpen, setIsPreviewTemplateDialogOpen] = useState(false);

  const [containerContentKey, setContainerContentKey] = useState(uuidv4());
  const templateMetadata = templatesMetadata?.find((template) => template.id === templateId);
  const bodyTemplate = templateMetadata?.document_type
    ? documentTypesDict?.[templateMetadata?.document_type]?.desc || templateMetadata?.document_type
    : '';
  const downloadUrl = `/api/v1/document_templates/organizations/${organization.id}/document_template/${templateId}`;

  const handleEditClick = () => {
    setIsEditTemplateDialogOpen(true);
  };

  const handlePreviewClick = () => {
    setIsPreviewTemplateDialogOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewTemplateDialogOpen(false);
  };

  // The DocumentTemplateCardContainer wraps Editor components for content updates. Due to Editors' tight coupling
  // with their initial content, direct updates don't trigger re-renders. By updating the component's key via useEffect,
  // we force a rerender to reflect content changes, ensuring the UI stays updated
  useEffect(() => {
    const key = uuidv4();
    setContainerContentKey(key);
  }, [templateId]);

  if (!templateMetadata) {
    return null;
  }

  return (
    <CardDialog
      containerClassName="overflow-y-auto border-1 border-slate-600 border-solid rounded-md mx-12"
      noCardTitle
    >
      <GenericTemplateCardButtonsContainer
        handleEditClick={handleEditClick}
        handlePreviewClick={handlePreviewClick}
        downloadUrl={downloadUrl}
      />
      <GenericTemplateContentContainer
        key={containerContentKey}
        titleTemplateLabel="Display Name:"
        titleTemplate={templateMetadata?.template_name}
        bodyTemplateLabel="Document Type:"
        bodyTemplate={bodyTemplate}
        templateComment={templateMetadata?.comment}
      />

      {isEditTemplateDialogOpen ? (
        <UpdateDocumentTemplateFormik
          documentTemplate={templateMetadata}
          orgId={organization.id}
          onDocumentUpdate={reloadTable}
          documentTemplates={templatesMetadata}
          isOpen={isEditTemplateDialogOpen}
          onClose={() => setIsEditTemplateDialogOpen(false)}
        />
      ) : null}
      {isPreviewTemplateDialogOpen ? (
        <DocumentTemplatePreviewContainer onClose={handleClosePreview} template={templateMetadata} />
      ) : null}
    </CardDialog>
  );
};

export default DocumentTemplateCardContainer;
