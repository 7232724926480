import React, { useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';

import { COUNTRIES_DICT } from '../../../../Types';
import { isJson, reportAxiosError } from '../../../../Utils';
import AutocompleteFormik from '../../../AutocompleteFormik';
import CardDialog from '../../../CardDialog';
import { TextFieldFormik } from '../../../TextFieldFormik';

import { useStyles } from '../../../../assets/styles';

const MIN_PREFIX_LENGTH = 2;
const MAX_PREFIX_LENGTH = 10;
const COUNTRIES_REQUIRE_ADDRESS = ['GB', 'NL', 'BE', 'AU'];
const COUNTRIES_REQUIRE_REGULATORY_BUNDLE = ['BE', 'GB', 'AU'];

const COUNTRY_CODES = {
  US: `${COUNTRIES_DICT['US']} (+1)`,
  GB: `${COUNTRIES_DICT['GB']} (+44)`,
  NL: `${COUNTRIES_DICT['NL']} (+31)`,
  IL: `${COUNTRIES_DICT['IL']} (+972)`,
  BE: `${COUNTRIES_DICT['BE']} (+32)`,
  AU: `${COUNTRIES_DICT['AU']} (+61)`,
};

const AddTwilioPhoneNumberDialog = ({ user, onEdit, onClose, isSuperUser }) => {
  const classes = useStyles();

  const [addressList, setAddressList] = useState([]);
  const [regulatoryBundleList, setRegulatoryBundleList] = useState([]);
  const [isUKCountry, setIsUKCountry] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const { data } = await axios.get(
          `/admin/api/v1/organizations/${user.organization_id}/communications/twilio_addresses`
        );
        setAddressList(data);
      } catch (error) {
        reportAxiosError(error);
      }
    };

    if (isEmpty(addressList) && isUKCountry) {
      fetchAddresses();
    }
  }, [addressList, setAddressList, user, isUKCountry]);

  useEffect(() => {
    const fetchRegulatoryBundles = async () => {
      try {
        const { data } = await axios.get(
          `/admin/api/v1/organizations/${user.organization_id}/communications/twilio_regulatory_bundles`
        );
        setRegulatoryBundleList(data);
      } catch (error) {
        reportAxiosError(error);
      }
    };

    if (isEmpty(regulatoryBundleList)) {
      fetchRegulatoryBundles();
    }
  }, [user, regulatoryBundleList]);

  const initialValues = {
    country_code: undefined,
    preferred_prefix: undefined,
    address: undefined,
    extra_attributes: undefined,
  };

  const validationSchema = Yup.object().shape({
    country_code: Yup.string().required('Required'),
    preferred_prefix: Yup.string()
      .nullable()
      .matches(/^[0-9]+$/, 'Prefix must be only digits')
      .max(MAX_PREFIX_LENGTH, `Prefix maximum length is ${MAX_PREFIX_LENGTH}`)
      .min(MIN_PREFIX_LENGTH, `Prefix minimum length is ${MIN_PREFIX_LENGTH}`),
    address: Yup.string().when('country_code', {
      is: (country_code) => COUNTRIES_REQUIRE_ADDRESS.includes(country_code),
      then: Yup.string().required('Required'),
    }),
    regulatory_bundle_sid: Yup.string().when('country_code', {
      is: (country_code) => COUNTRIES_REQUIRE_REGULATORY_BUNDLE.includes(country_code),
      then: Yup.string().required('Required'),
    }),
    extra_attributes: Yup.string()
      .nullable()
      .test('not-valid JSON', 'Not a valid JSON', (content) => (!isEmpty(content) ? isJson(content) : true)),
  });

  const onSubmit = async (values, formikProps) => {
    try {
      await axios.post(
        `/admin/api/v1/organizations/${user.organization_id}/users/${user.id}/communications/twilio_phone_number`,
        values
      );
      await onEdit();
    } catch (error) {
      reportAxiosError(error);
      formikProps.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, values }) => {
        const isAddressRequired = COUNTRIES_REQUIRE_ADDRESS.includes(values.country_code);
        const isRegulatoryBundleRequired = COUNTRIES_REQUIRE_REGULATORY_BUNDLE.includes(values.country_code);

        if (isAddressRequired && isEmpty(addressList)) {
          setIsUKCountry(true);
        }

        return (
          <CardDialog isDialog open title="Add Phone Number" onClose={onClose}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AutocompleteFormik
                  id="country_code"
                  label="Select Country"
                  options={Object.keys(COUNTRY_CODES)}
                  getOptionLabel={(option) => COUNTRY_CODES[option]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="preferred_prefix"
                  label="Preferred Prefix (Optional)"
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
              {isAddressRequired && (
                <Grid item xs={6}>
                  <AutocompleteFormik
                    id="address"
                    label="Select Address"
                    options={Object.keys(addressList)}
                    getOptionLabel={(option) => addressList[option]}
                  />
                </Grid>
              )}
              {isRegulatoryBundleRequired && (
                <Grid item xs={6}>
                  <AutocompleteFormik
                    id="regulatory_bundle_sid"
                    label="Select Regulatory Bundle"
                    options={Object.keys(regulatoryBundleList)}
                    getOptionLabel={(option) => regulatoryBundleList[option]}
                  />
                </Grid>
              )}
              {isSuperUser && (
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="extra_attributes"
                    label="Twilio Worker Attributes (Optional)"
                    rows={3}
                    className={classes.textField}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Add
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

AddTwilioPhoneNumberDialog.propTypes = {
  user: object.isRequired,
  onEdit: func.isRequired,
  onClose: func.isRequired,
  isSuperUser: bool.isRequired,
};

export default AddTwilioPhoneNumberDialog;
