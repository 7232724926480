import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';

interface IKanbanConfigurationRoutes {
  getBaseUrl: () => string;
  getMoveCategoryUpUrl: (category_id: number) => string;
  getMoveCategoryDownUrl: (category_id: number) => string;
  getEnableCategoryUrl: (category_id: number) => string;
  getDisableCategoryUrl: (category_id: number) => string;
}

export const useKanbanConfigurationRoutes = (): IKanbanConfigurationRoutes => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  function getBaseUrl() {
    return `/sysconfig/api/v1/notification_kanban/organizations/${organization.id}`;
  }

  const getMoveCategoryUpUrl = (category_id: number) => `${getBaseUrl()}/category/${category_id}/up`;
  const getMoveCategoryDownUrl = (category_id: number) => `${getBaseUrl()}/category/${category_id}/down`;
  const getEnableCategoryUrl = (category_id: number) => `${getBaseUrl()}/category/${category_id}/enable`;
  const getDisableCategoryUrl = (category_id: number) => `${getBaseUrl()}/category/${category_id}/disable`;

  return {
    getBaseUrl,
    getMoveCategoryUpUrl,
    getMoveCategoryDownUrl,
    getEnableCategoryUrl,
    getDisableCategoryUrl,
  };
};
