import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import EditorFormik from '~/components/communications/EmailCommunicationCard/EmailEditor/EditorFormik';
import { MainCard, Text } from '~/components/core';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import ArrayFieldFormik from '~/components/core/Formik/NestedFieldFormik/ArrayFieldFormik';
import type {
  EmailAcknowledgmentSubmitValues,
  EmailBuilderAcknowledgmentProps,
} from '~/components/SystemConfiguration/EmailConfiguration/emailConfigurationTypes';
import { NumericTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';
import EMAIL_ACKNOWLEDGMENT_FREQUENCY_SETTING from '~/server_shared/generated-types/EMAIL_ACKNOWLEDGMENT_FREQUENCY_SETTING';
import { reportAxiosError } from '~/Utils';
import { validateNoneEmptyHtmlContent } from '~/Utils/validationUtils';

import AddIcon from '../../icons/AddIcon';

const EmailAcknowledgmentBuilderContainer: React.FC<EmailBuilderAcknowledgmentProps> = ({
  handleClose,
  handleEmailAcknowledgmentSubmit,
  reloadTable,
  emailAcknowledgmentData,
}) => {
  return (
    <CardDialog isDialog title="Edit Acknowledgment Email" onClose={handleClose} fullWidth maxWidth="md">
      <Formik
        initialValues={{
          id: emailAcknowledgmentData?.id,
          email_content: emailAcknowledgmentData?.email_content || '',
          frequency: emailAcknowledgmentData?.frequency || 1,
          frequency_setting: emailAcknowledgmentData?.frequency_setting || 'week',
          excluded_emails: emailAcknowledgmentData?.excluded_emails,
        }}
        validationSchema={Yup.object().shape({
          email_content: Yup.string()
            .trim()
            .required('Email content is required')
            .test('not-empty-html', 'Email content is required', validateNoneEmptyHtmlContent),
          frequency: Yup.number()
            .min(0)
            .integer()
            .required('Frequency must be a valid number')
            .typeError('Frequency must be a valid number'),
          excluded_emails: Yup.array().of(
            Yup.object().shape({
              excluded_email: Yup.string()
                .email('Invalid email format')
                .required("Email input can't be empty")
                .nullable(false),
            })
          ),
        })}
        onSubmit={async (values: EmailAcknowledgmentSubmitValues, { setSubmitting }) => {
          try {
            await handleEmailAcknowledgmentSubmit(values, values.id);
            reloadTable();
            handleClose();
          } catch (error) {
            await reportAxiosError(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <div className="flex flex-col gap-20">
              <EditorFormik
                id="email_content"
                label="Email Content"
                classNameEditor="min-h-[150px] overflow-auto"
                displayConfig={{
                  font: true,
                  textColor: true,
                  textStyle: true,
                  align: true,
                  link: true,
                }}
              />
              <MainCard title="Exclude Sender(s)" type="contained">
                <Text
                  variant={Text.VARIANTS.SM}
                  weight={Text.WEIGHTS.REGULAR}
                  colorVariant={Text.COLOR_VARIANTS.SECONDARY}
                >
                  You can use wildcards to exclude entire domains (e.g., *@company.com) or specific email patterns.
                  Emails matching these will not trigger an acknowledgment.
                </Text>
                <ArrayFieldFormik
                  fieldId="excluded_emails"
                  innerObjectConfig={[
                    {
                      id: 'excluded_email',
                      label: 'Email Address',
                      type: 'text',
                    },
                  ]}
                  initialNewItemValues={{
                    excluded_email: '',
                  }}
                  allowDeletingInitialValues
                  addNewItemButtonText="add another email"
                  addNewItemButtonStartIcon={<AddIcon iconColor="currentColor" />}
                />
              </MainCard>
              <MainCard title="Frequency" type="contained">
                <Text
                  variant={Text.VARIANTS.SM}
                  weight={Text.WEIGHTS.REGULAR}
                  colorVariant={Text.COLOR_VARIANTS.SECONDARY}
                >
                  Set the minimum time between acknowledgments sent to the same sender. Use 0 to send an acknowledgment
                  for every incoming email.
                </Text>
                <div className="flex gap-20">
                  <NumericTextFieldFormik
                    id="frequency"
                    label=""
                    className="flex-1"
                    decimalScale={0}
                    allowNegative={false}
                  />
                  <TextFieldFormik id="frequency_setting" label="" className="flex-1" select fullWidth>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-expect-error TS2322*/}
                    {Object.entries(EMAIL_ACKNOWLEDGMENT_FREQUENCY_SETTING).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </div>
              </MainCard>
              <div className="mt-16 flex w-full justify-end">
                <DialogFooterActions
                  primaryLabel="Save"
                  onClickPrimary={handleSubmit}
                  onClickSecondary={handleClose}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </CardDialog>
  );
};

export default EmailAcknowledgmentBuilderContainer;
