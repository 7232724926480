import React from 'react';
import { Formik, getIn, useFormikContext } from 'formik';
import { isNil, snakeCase } from 'lodash';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import { Text } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';
import { LobCheckboxMultiselectFormik } from '~/components/TPA/LOB/LobCheckboxMultiselectFormik';
import type { DocumentType } from '~/components/types/document-type-types';
import { reportAxiosError } from '~/Utils';

interface UpsertDocumentTypeDialogProps {
  documentTypesDict: Record<string, DocumentType>;
  onClose: () => void;
  onSubmit: (values: Record<string, unknown>) => Promise<void>;
  documentType?: DocumentType;
}

const FORMIK_IDS = {
  KEY: 'key',
  DISPLAY_NAME: 'display_name',
  DESCRIPTION: 'description',
  IS_ALL_LOBS: 'is_all_lobs',
  LOBS: 'lobs',
};

interface UpsertDocumentTypeDialogInnerProps {
  isUpdateMode: boolean;
}

const UpsertDocumentTypeDialogInner: React.FC<UpsertDocumentTypeDialogInnerProps> = ({ isUpdateMode }) => {
  const { isSubmitting, values } = useFormikContext<DocumentType>();

  useSetDefaultFieldsOnChange(getIn(values, FORMIK_IDS.DISPLAY_NAME), {
    [FORMIK_IDS.KEY]: isUpdateMode ? getIn(values, FORMIK_IDS.KEY) : snakeCase(getIn(values, FORMIK_IDS.DISPLAY_NAME)),
  });

  return (
    <div className="grid gap-20">
      <div>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
          Details
        </Text>
        <div className="grid grid-cols-2 gap-20">
          <TextFieldFormik id={FORMIK_IDS.DISPLAY_NAME} label="Document Type Name" disabled={isSubmitting} />
          <TextFieldFormik id={FORMIK_IDS.KEY} label="Document Type Key" disabled={isSubmitting || isUpdateMode} />
        </div>
        <div className="grid grid-cols-1 gap-20">
          <TextFieldFormik id={FORMIK_IDS.DESCRIPTION} label="Description" disabled={isSubmitting} />
        </div>
      </div>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
        Select Lines of Business
      </Text>
      <div className="grid grid-cols-1 gap-20">
        <LobCheckboxMultiselectFormik
          lobsFieldId={FORMIK_IDS.LOBS}
          allSelectedFieldId={FORMIK_IDS.IS_ALL_LOBS}
          disabled={isSubmitting}
          onlyNewValues={!isUpdateMode}
        />
      </div>
    </div>
  );
};

const UpsertDocumentTypeDialog: React.FC<UpsertDocumentTypeDialogProps> = ({
  documentTypesDict,
  onClose,
  onSubmit,
  documentType,
}) => {
  return (
    <Formik
      initialValues={{
        [FORMIK_IDS.DISPLAY_NAME]: documentType?.display_name ?? '',
        [FORMIK_IDS.DESCRIPTION]: documentType?.description ?? '',
        [FORMIK_IDS.KEY]: documentType?.key ?? '',
        [FORMIK_IDS.IS_ALL_LOBS]: documentType?.is_all_lobs ?? false,
        [FORMIK_IDS.LOBS]: documentType?.lobs ?? [],
      }}
      validationSchema={Yup.object().shape({
        [FORMIK_IDS.DISPLAY_NAME]: Yup.string()
          .required('Required')
          .trim()
          .lowercase()
          .notOneOf(
            Object.values(documentTypesDict)
              .filter((r) => r.id !== documentType?.id)
              .map((r) => r.display_name.toLowerCase().trim()),
            'Must be unique'
          ),
        [FORMIK_IDS.KEY]: Yup.string()
          .required('Required')
          .trim()
          .matches(/^[a-z0-9_]*$/, 'Can only contain lowercase letters, numbers and underscores')
          .notOneOf(
            Object.keys(documentTypesDict).filter((key) => key !== documentType?.key),
            'Must be unique'
          ),
        [FORMIK_IDS.DESCRIPTION]: Yup.string().required('Required').trim(),
        [FORMIK_IDS.IS_ALL_LOBS]: Yup.bool(),
        [FORMIK_IDS.LOBS]: Yup.array().when('is_all_lobs', {
          is: false,
          then: Yup.array().of(Yup.string()).min(1, 'Required'),
        }),
      })}
      onSubmit={async (values: Record<string, unknown>, formikProps) => {
        try {
          await onSubmit(values);
          onClose();
        } catch (error) {
          formikProps.setSubmitting(false);
          await reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title={documentType ? 'Edit Document Type' : 'Create Document Type'}
            isDialog
            onClose={onClose}
            maxWidth="md"
          >
            <UpsertDocumentTypeDialogInner isUpdateMode={!isNil(documentType)} />
            <div className="mt-px mt-32 flex w-full justify-end">
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default UpsertDocumentTypeDialog;
