import React, { useState } from 'react';
import { get } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import TextField from '~/components/core/Molecules/Fields/TextField';

import FieldConfigurationCard from '../FieldConfigurationCard/FieldConfigurationCard';

import BaseConfigurationSection from './BaseConfigurationSection';

import { useStyles } from '../../../../../assets/styles';

const ID_PREFIX = 'configuration.incident_details.pre_defined_fields';
const TITLE = 'Witnesses';
const KEY = 'witnesses';
const SECTION_ID = `configuration.${KEY}`;

const WitnessesSection = () => {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(false);

  // Enforce all supplementary section to be hidden if the section show is set to false
  useFormikChangeListener({
    listenForKeys: [`${SECTION_ID}.active`],
    onChange: (values, _) => {
      !get(values, `${SECTION_ID}.active`, false) ? setIsHidden(true) : setIsHidden(false);
    },
    runOnFirstRender: true,
  });

  return (
    <BaseConfigurationSection title={TITLE} subtitle={`Key: ${KEY}`} isHidden={isHidden} isSection>
      <Grid item xs={6}>
        <TextField label="Display Name" value={TITLE} fullWidth disabled />
      </Grid>
      <Grid item xs={6}>
        <TextField value={KEY} label="Key" fullWidth disabled />
      </Grid>
      <Grid item xs={2}>
        <SwitchFormik id={`${SECTION_ID}.active`} label="Show" className={classes.formsSwitch} hideErrorGap />
      </Grid>
      <Grid item xs={10} />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="were_there_any_witnesses" disabledCollapse={isHidden} />
      <FieldConfigurationCard idPrefix={ID_PREFIX} fieldId="witnesses_note" disabledCollapse={isHidden} />
      <FieldConfigurationCard
        idPrefix={SECTION_ID}
        fieldId="contact_ref_id"
        forceRequired
        forceDisabled
        disabledCollapse={isHidden}
      />
      <FieldConfigurationCard
        idPrefix={SECTION_ID}
        fieldId="relationship_to_policyholder"
        disabledCollapse={isHidden}
      />
      <FieldConfigurationCard idPrefix={SECTION_ID} fieldId="description" forceDisabled disabledCollapse={isHidden} />
    </BaseConfigurationSection>
  );
};

export default WitnessesSection;
