import React from 'react';

import { Text } from '~/components/core';
import { FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import { StatesConfigurationFormik } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/StatesConfigurationFormik';
import NotificationConfigurationFieldsFormikInner from '~/components/SystemConfiguration/NotificationUtils/NotificationConfigurationFieldsFormikInner';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isOrganizationUsOrg } from '~/Utils';

const RuleNotificationConfigurationFieldsFormik: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  return (
    <div>
      {isOrganizationUsOrg(organization) ? (
        <FeatureEnabled
          featureFlag={CONFIGURATION_FEATURES_NAMES.MULTI_STATE_NOTIFICATIONS}
          organization={organization}
        >
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            Select Loss Location by States
          </Text>
          <StatesConfigurationFormik />
        </FeatureEnabled>
      ) : null}
      <NotificationConfigurationFieldsFormikInner />
    </div>
  );
};

export default RuleNotificationConfigurationFieldsFormik;
