import React, { useState } from 'react';
import { Chip } from '@mui/material';
import axios from 'axios';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Switch from '~/components/core/Atomic/Switch';
import SortableTable from '~/components/core/Tables/SortableTable';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';

import { isFeatureEnabled, reportAxiosError } from '../../../Utils';
import CardDialog from '../../CardDialog';
import { FsButton, Heading, LoadingSwitch, Text } from '../../core';
import { PencilIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';

import AuthorizedUsersDialog from './AuthorizedUsersDialog';
import GeneralEmailDialog from './GeneralEmailDialog';

import { useStyles } from '../../../assets/styles';
import styles from './generalEmailConfiguration.module.scss';

const GeneralEmailConfig = () => {
  const { organization, reloadOperationalDetails, organizationOperationalDetails } = useSysconfig();
  const classes = useStyles();

  const generalEmailClassificationConfigurationsUrl = `/sysconfig/api/v1/organizations/${organization.id}/general_email_classification_configuration`;

  const emailDomains = organizationOperationalDetails.emailDomains;

  const {
    isLoading: isLoadingConfiguration,
    isError: isErrorConfiguration,
    data: generalEmailClassificationConfigurationsAndUsers,
    reloadData: reloadGeneralEmailClassificationConfigurationsAndUsers,
  } = useDataFetcher(generalEmailClassificationConfigurationsUrl);

  const generalEmailClassificationConfigurations =
    generalEmailClassificationConfigurationsAndUsers?.general_email_classification_configurations;
  const specialUsers = generalEmailClassificationConfigurationsAndUsers?.special_users;

  const [isAddGeneralQueueDialogueOpen, setAddGeneralQueueDialogOpen] = useState(false);
  const [emailDomainToAddGQ, setEmailDomainToAddGQ] = useState(null);
  const [isUserDialogOpen, setUserDialogOpen] = useState(false);

  const emailDomainIdsFromGQConfigs =
    generalEmailClassificationConfigurations?.map((config) => config.email_domain_id) || [];

  const unusedEmailDomains = emailDomains?.filter(
    (emailDomain) => !emailDomainIdsFromGQConfigs.includes(emailDomain.id)
  );

  const handleUpdateConfig = async (configId, configUpdate) => {
    try {
      await axios.patch(`${generalEmailClassificationConfigurationsUrl}/${configId}`, configUpdate);
      await reloadGeneralEmailClassificationConfigurationsAndUsers();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleIncludeSignature = async (configId, shouldIncludeSignature) => {
    const configUpdate = {
      should_include_signature: shouldIncludeSignature,
    };
    await handleUpdateConfig(configId, configUpdate);
  };

  const handleAllowSendToClive = async (configId, shouldSendToClive) => {
    const configUpdate = {
      should_send_to_clive: shouldSendToClive,
    };
    await handleUpdateConfig(configId, configUpdate);
  };

  const columnData = [
    {
      id: 'organization_email',
      numeric: false,
      label: 'Email Domain',
    },
    {
      id: 'is_include_signature',
      label: 'Include Signature',
      specialCell: (domainData) => (
        <Switch
          key={domainData.id}
          checked={domainData.should_include_signature}
          className={classes.formsSwitch}
          onChange={async (_, isIncluded) => {
            await handleIncludeSignature(domainData.id, isIncluded);
          }}
        />
      ),
    },
  ];

  // NOTE: after removing the FF, only show if Clive enabled
  if (isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.GQ_SEND_EMAIL_TO_CLIVE_OPTION)) {
    columnData.push({
      id: 'should_send_to_clive',
      label: 'Enable Clive Recommendation',
      specialCell: (domainData) => (
        <Switch
          key={domainData.id}
          checked={domainData.should_send_to_clive}
          className={classes.formsSwitch}
          onChange={async (_, shouldSendToClive) => {
            await handleAllowSendToClive(domainData.id, shouldSendToClive);
          }}
        />
      ),
    });
  }

  const handleOpenAddGeneralQueueDialog = (emailDomain) => {
    setAddGeneralQueueDialogOpen(true);
    setEmailDomainToAddGQ(emailDomain);
  };

  const handleCloseAddGeneralQueueDialog = () => {
    setAddGeneralQueueDialogOpen(false);
    setEmailDomainToAddGQ(null);
  };

  const handleSubmitAddGeneralQueue = async (email_address, email_domain_id) => {
    try {
      const payload = { general_email: email_address, email_domain_id, is_enabled: true };
      await axios.post(generalEmailClassificationConfigurationsUrl, payload);
      await reloadGeneralEmailClassificationConfigurationsAndUsers();
      await reloadOperationalDetails();
      setAddGeneralQueueDialogOpen(false);
      setEmailDomainToAddGQ(null);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleOpenUserDialog = () => {
    setUserDialogOpen(true);
  };

  const handleCloseUserDialog = () => {
    setUserDialogOpen(false);
  };

  const handleUpdateSpecialUsers = async ({ user_ids }) => {
    try {
      const payload = { user_ids };
      await axios.post(`${generalEmailClassificationConfigurationsUrl}/users`, payload);
      await reloadGeneralEmailClassificationConfigurationsAndUsers();
      handleCloseUserDialog();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <LoadingSwitch isError={isErrorConfiguration} isLoading={isLoadingConfiguration}>
      <CardDialog noCardTitle>
        <CardDialog title="General Email Classification">
          {generalEmailClassificationConfigurations?.length > 0 && (
            <div className={styles.sectionContainer}>
              <Heading variant={Heading.TYPES.H3}>Email Addresses</Heading>
              <SortableTable columns={columnData} rows={generalEmailClassificationConfigurations} />
            </div>
          )}
          {unusedEmailDomains?.length > 0 && (
            <div className={styles.sectionContainer}>
              <Heading variant={Heading.TYPES.H3}>
                {unusedEmailDomains?.length === 1 ? 'Not implemented domain' : 'Other Domains'}
              </Heading>
              {unusedEmailDomains.map((unusedEmailDomain) => (
                <Grid container key={unusedEmailDomain.id} className={styles.domainsContainer}>
                  <Grid item xs={2}>
                    <Text colorVariant={Text.COLOR_VARIANTS.SECONDARY} variant={Text.VARIANTS.SM}>
                      @{unusedEmailDomain.domain}
                    </Text>
                  </Grid>
                  <Grid item xs={2}>
                    <FsButton color="primary" onClick={() => handleOpenAddGeneralQueueDialog(unusedEmailDomain)}>
                      <AddIcon className={classes.leftButtonIcon} />
                      Add general email queue
                    </FsButton>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
          <div className={styles.sectionContainer}>
            <div className={styles.usersContainer}>
              <Heading variant={Heading.TYPES.H3}>Set Authorized Editing Users</Heading>
              <InlineIconButton className={classes.inlineEditIcon} icon={PencilIcon} onClick={handleOpenUserDialog} />
            </div>
            <div>
              {specialUsers?.map((user) => (
                <Chip size="small" label={user.username} className={classes.chip} key={user.id} />
              ))}
            </div>
          </div>
          {isAddGeneralQueueDialogueOpen && (
            <GeneralEmailDialog
              emailDomain={emailDomainToAddGQ}
              onSubmit={handleSubmitAddGeneralQueue}
              onCancel={handleCloseAddGeneralQueueDialog}
            />
          )}
          {isUserDialogOpen && (
            <AuthorizedUsersDialog
              onCancel={handleCloseUserDialog}
              onSubmit={handleUpdateSpecialUsers}
              existingUsers={specialUsers}
            />
          )}
        </CardDialog>
      </CardDialog>
    </LoadingSwitch>
  );
};

export default GeneralEmailConfig;
