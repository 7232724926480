import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { noop } from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';

import { FsIconButton, LoadingSwitch } from '../../../../core';
import { DEFAULT_PROFILES_CONFIG } from '../../../../core/Permissions/PermissionUtils';
import { ClipboardIcon } from '../../../../icons';
import TextFieldFormik from '../../../../TextFieldFormik';
import useDataFetcher from '../../../../useDataFetcher';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { ViewProfileDialog } from '../../../Tabs/PermissionsProfiles/ProfileDialogs/ViewProfileDialog';

import { useStyles } from '../../../../../assets/styles';
import styles from './UserPermissionsDialogs.module.scss';

const PROFILE_ID_FIELD_KEY = 'profile_id';

export const PermissionsProfileSelect = ({ useDefaults }) => {
  return useDefaults ? <PermissionProfileSelectDefaults /> : <PermissionProfilesSelectRemote />;
};

PermissionsProfileSelect.propTypes = {
  useDefaults: PropTypes.bool,
};

const PermissionProfilesSelectRemote = () => {
  const { organization } = useSysconfig();
  const {
    data: permissionProfiles,
    isError,
    isLoading,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/permissions/profiles`, []);

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <PermissionProfilesSelectInner permissionProfiles={permissionProfiles} />
    </LoadingSwitch>
  );
};

const PermissionProfileSelectDefaults = () => {
  const permissionsProfiles = Object.values(DEFAULT_PROFILES_CONFIG)
    .filter(({ isSelectableInWizard }) => isSelectableInWizard)
    .map((profile) => ({
      ...profile,
      profile_permissions: Object.entries(profile.permissions).map(([action, verbs]) => ({
        permission_action: action,
        permission_verbs: verbs,
      })),
    }));

  return <PermissionProfilesSelectInner permissionProfiles={permissionsProfiles} />;
};

const PermissionProfilesSelectInner = ({ permissionProfiles }) => {
  const [viewProfileDialogOpen, setViewProfileDialogOpen] = React.useState(false);
  const [selectedProfile, setSelectedProfile] = React.useState(null);

  useFormikChangeListener({
    onChange: (values) => {
      const profileId = getIn(values, PROFILE_ID_FIELD_KEY);
      const profile = permissionProfiles.find(({ id }) => id === profileId);
      setSelectedProfile(profile);
    },
    listenForKeys: [PROFILE_ID_FIELD_KEY],
    runOnFirstRender: true,
  });

  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} alignContent="space-between" direction="row" className={styles.inLineContainer}>
        <div className={styles.profileSelectWrapper}>
          <TextFieldFormik id={PROFILE_ID_FIELD_KEY} select label="Profile" className={classes.textField} fullWidth>
            {permissionProfiles?.map(({ id, display_name }) => (
              <MenuItem key={id} value={id}>
                {display_name}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
        <div className={styles.profileDetailsButtonWrapper}>
          <FsIconButton
            className={styles.profileDetailsButton}
            icon={ClipboardIcon}
            disabled={!selectedProfile}
            onClick={() => setViewProfileDialogOpen(true)}
          />
        </div>
      </Grid>
      {viewProfileDialogOpen && (
        <ViewProfileDialog
          profile={selectedProfile}
          onClose={() => setViewProfileDialogOpen(false)}
          onDelete={noop}
          reloadData={noop}
          hideActions
        />
      )}
    </>
  );
};

PermissionProfilesSelectInner.propTypes = {
  permissionProfiles: PropTypes.array.isRequired,
};
