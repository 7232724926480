import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { Card } from '~/components/core/Cards/Card';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import RadioWithButtonWrapperFormik from '~/components/core/Formik/RadioWithButtonWrapperFormik';
import TextAreaWithTokens from '~/components/core/Formik/TextAreaWithTokens';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { EventScheduleSelect } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/EventScheduleSelect';
import RuleNotificationConfigurationFieldsFormik from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/RuleNotificationConfigurationFieldsFormik';
import { useOrganizationUnits } from '~/components/SystemConfiguration/Users/hooks/useOrganizationUnits';

import { NOTIFICATION_MIXPANEL_EVENT_SOURCES, NOTIFICATION_MIXPANEL_EVENTS } from '../../../../../pocs/mixpanel';
import {
  CONFIGURATION_FEATURES_NAMES,
  NOTIFICATIONS_ENTITY_STATUSES,
  NOTIFICATIONS_SCOPES,
} from '../../../../../Types';
import { isFeatureEnabled, isOrganizationUsOrg, reportAxiosError } from '../../../../../Utils';
import cn from '../../../../../Utils/cn';
import { getLobDescription, getLobIcon } from '../../../../../Utils/lobUtils';
import AutocompleteFormik from '../../../../AutocompleteFormik';
import CardDialog from '../../../../CardDialog';
import mixpanel from '../../../../CmsMain/mixpanel';
import { Text } from '../../../../core';
import { ErrorHelperTextFormik } from '../../../../core/Formik/ErrorHelperTextFormik';
import { InfoIcon, WarningIcon } from '../../../../icons';
import InlineIconButton from '../../../../InlineIconButton';
import {
  MultiSelectTextFieldFormik,
  TextFieldFormik,
  useAllOptionOnMultiSelect,
  useSetDefaultFieldsOnChange,
} from '../../../../TextFieldFormik';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { ALL_OPTION, useNotificationConfigurationTool } from '../utils';

import { ConditionsFormik } from './ConditionsFormik';
import { StatesConfigurationFormik } from './StatesConfigurationFormik';

import { useStyles } from '../../../../../assets/styles';
import styles from './notificationDialogInner.module.scss';

export const NotificationDialogInner = ({ rule, onClose, subOrgIdToName, events, conditions }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values, setFieldValue } = useFormikContext();
  const {
    organization,
    organizationOperationalDetails: { notificationUsableTokensMap },
  } = useSysconfig();
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [selectedEventConfig, setSelectedEventConfig] = useState({});
  const [relevantTokens, setRelevantTokens] = useState({});
  const [lobOptions, setLobOptions] = useState([]);
  const [scopeTypesOptions, setScopeTypesOptions] = useState({ claim: [], exposure: [] });
  const { lobConfigurationsDict } = useLobConfiguration();
  const { recipientTypes } = useNotificationConfigurationTool();

  const { isUnitsLoading, isUnitsError, getActiveUnitsAndSelectedUnit } = useOrganizationUnits({ organization });
  const organizationUnitsById = getActiveUnitsAndSelectedUnit(rule?.organization_unit_id);

  const isSubmitDisabled = isSubmitting || isUnitsLoading || isUnitsError;

  const theme = useTheme();

  const fetchLobs = useCallback(
    async (sub_orgs_ids) => {
      setLobOptions([]);

      try {
        const { data: supportedClaimTypesForSubOrg } = await axios.get(
          `/api/v1/organizations/${organization.id}/lobs`,
          {
            params: { sub_orgs_ids: sub_orgs_ids.filter((id) => id !== 'all') },
          }
        );

        setLobOptions(supportedClaimTypesForSubOrg);
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [organization.id]
  );

  const fetchCoveragesAndIncidentTypes = useCallback(
    async (sub_orgs_ids, lob) => {
      setScopeTypesOptions({ claim: [], exposure: [] });

      try {
        if (lob !== 'all') {
          const [{ data: supportedCoveragesForSubOrgAndLob }, { data: supportedIncidentTypesForSubOrgAndLobWithData }] =
            await Promise.all([
              axios.get(`/api/v1/organizations/${organization.id}/coverages`, { params: { sub_orgs_ids, lob } }),
              axios.get(`/api/v1/organizations/${organization.id}/incident_types`, { params: { sub_orgs_ids, lob } }),
            ]);

          const supportedIncidentTypesForSubOrgAndLob = _.mapValues(
            supportedIncidentTypesForSubOrgAndLobWithData,
            'desc'
          );
          setScopeTypesOptions({
            claim: Object.keys(supportedIncidentTypesForSubOrgAndLob)
              .map((key) => ({ desc: supportedIncidentTypesForSubOrgAndLob[key], key: `${lob}_${key}` }))
              .concat([ALL_OPTION]),
            exposure: Object.keys(supportedCoveragesForSubOrgAndLob)
              .map((key) => ({ desc: supportedCoveragesForSubOrgAndLob[key], key: `${lob}_${key}` }))
              .concat([ALL_OPTION]),
          });
        }
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [organization]
  );

  useEffect(() => {
    setFilteredEvents(events.filter((event) => event.scope === values.scope));
  }, [values.scope, events]);

  useEffect(() => {
    const selectedEventConfig = events.find((event) => values.event_key && event.key === values.event_key.key);
    setSelectedEventConfig(selectedEventConfig);
    if (selectedEventConfig?.is_time_based) {
      setFieldValue('schedule_immediate_or_custom', 'custom');
    }
    if (selectedEventConfig && selectedEventConfig.has_type) {
      setEventTypeOptions(selectedEventConfig.types.concat(ALL_OPTION));
    } else {
      setEventTypeOptions([]);
    }
  }, [values.event_key, events, setFieldValue]);

  useEffect(() => {
    setRelevantTokens(
      _.pickBy(notificationUsableTokensMap, (token) => {
        const doesMatchScope = token.scope === 'claim' || (values.scope && token.scope === values.scope);
        const doesMatchEvent =
          token.context === 'claim' ||
          token.context === 'exposure' ||
          (values.event_key && values.event_key.context === token.context);
        return doesMatchScope && doesMatchEvent;
      })
    );
  }, [values.scope, values.event_key, notificationUsableTokensMap]);

  const noSpecificLobSelected = !values.lob || values.lob === 'all';
  const noSpecificSubOrgSelected =
    organization.sub_organizations_enabled &&
    (!values.sub_organization_ids.length || values.sub_organization_ids.indexOf('all') > -1);
  const shouldDisableScopeTypesSelection = !values.scope || noSpecificLobSelected || noSpecificSubOrgSelected;

  const getUnitName = (unitId) => {
    return isUnitsError
      ? 'Error loading units'
      : isUnitsLoading
      ? 'Loading...'
      : organizationUnitsById[unitId]?.name || '';
  };

  useAllOptionOnMultiSelect(values.scope_types, 'scope_types', ALL_OPTION);
  useAllOptionOnMultiSelect(values.event_criteria_types, 'event_criteria_types', ALL_OPTION);
  useAllOptionOnMultiSelect(values.sub_organization_ids, 'sub_organization_ids', 'all');

  useSetDefaultFieldsOnChange(values.scope, {
    conditions: [],
    title: '',
    content: '',
    scope_types: [ALL_OPTION],
    event_key: null,
  });

  useSetDefaultFieldsOnChange(values.event_key, {
    conditions: [],
    event_criteria_types: [ALL_OPTION],
  });

  useSetDefaultFieldsOnChange(
    values.lob,
    {
      conditions: [],
      scope_types: [ALL_OPTION],
      event_key: null,
    },
    () =>
      fetchCoveragesAndIncidentTypes(
        values.sub_organization_ids.indexOf('all') > -1 ? [] : values.sub_organization_ids,
        values.lob
      )
  );

  useSetDefaultFieldsOnChange(values.sub_organization_ids, { lob: '' }, () => fetchLobs(values.sub_organization_ids));
  useSetDefaultFieldsOnChange(values.recipient, { organization_unit_id: undefined });

  const isConditionsFormikDisabled =
    isSubmitting ||
    (organization.sub_organizations_enabled && _.isEmpty(values.sub_organization_ids)) ||
    !values.lob ||
    !values.scope ||
    !values.scope_status;

  const isNotifications2Enabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  return (
    <CardDialog
      isDialog
      title={`${rule ? 'Edit' : 'Add '} Notification`}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions disabled={isSubmitDisabled} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <Grid container spacing={2} className={styles.notificationDialogInner}>
        {organization.sub_organizations_enabled && (
          <Grid item xs={12}>
            <AlertBanner
              note="Editing the sub-orgs or LoBs will clear all conditions"
              title="Please Note"
              alertType={AlertBanner.ALERT_TYPES.INFO}
              withIcon
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Card title="Context" cardType={Card.CARD_TYPES.PRIMARY}>
            {organization.sub_organizations_enabled && (
              <div className={styles.subOrgSection}>
                <Text
                  variant={Text.VARIANTS.SM}
                  weight={Text.WEIGHTS.REGULAR}
                  colorVariant={Text.COLOR_VARIANTS.PRIMARY}
                >
                  Select Sub Organization
                </Text>

                <MultiSelectTextFieldFormik
                  id="sub_organization_ids"
                  label="Sub Organizations"
                  disabled={isSubmitting}
                  options={organization.sub_organizations.map((subOrg) => subOrg.id)}
                  renderValue={() => ''}
                  renderOption={(subOrgId) =>
                    subOrgId === 'all' ? 'All (inc. future sub-orgs)' : subOrgIdToName[subOrgId]
                  }
                  className={classes.formTextFieldNoErrorSpacing}
                  withOptionChips
                  fullWidth
                  sortAlphabetic
                  addAllOption
                  allOptionValue="all"
                />
              </div>
            )}
            <Grid item xs={12}>
              <div className={styles.selectLobSection}>
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className={styles.selectLobTitle}>
                  Select line of business
                </Text>
                <div style={{ display: 'flex' }}>
                  <RadioWithButtonWrapperFormik
                    className={styles.radioButtons}
                    labelClassName={styles.radioButtonsLabels}
                    id="lob"
                    key="all"
                    value="all"
                    text="All"
                    disabled={
                      isSubmitting || (organization.sub_organizations_enabled && _.isEmpty(values.sub_organization_ids))
                    }
                    icon={
                      <InlineIconButton
                        icon={InfoIcon}
                        tooltipTitle="Including future line of business"
                        wrapWithSpan
                        className={classes.disabledFilledIcon}
                        size={16}
                        iconStyle={{ marginBottom: '-2px' }}
                        onClick={() => null}
                      />
                    }
                    iconLocation="end"
                  />
                  {lobOptions.map((lob) => (
                    <RadioWithButtonWrapperFormik
                      className={styles.radioButtons}
                      labelClassName={styles.radioButtonsLabels}
                      icon={getLobIcon({ lob, lobConfigurationsDict })}
                      id="lob"
                      key={lob}
                      value={lob}
                      text={getLobDescription(lob, lobConfigurationsDict)}
                      disabled={
                        isSubmitting ||
                        (organization.sub_organizations_enabled && _.isEmpty(values.sub_organization_ids))
                      }
                    />
                  ))}
                </div>
                <ErrorHelperTextFormik id="lob" />
              </div>
            </Grid>

            {/*States selection is rendered inside NotificationConfigurationFieldsFormikInner for notifications 2.0 */}
            {!isNotifications2Enabled && isOrganizationUsOrg(organization) ? (
              <FeatureEnabled
                featureFlag={CONFIGURATION_FEATURES_NAMES.MULTI_STATE_NOTIFICATIONS}
                organization={organization}
              >
                <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                  Select Loss Location by States
                </Text>
                <Grid item xs={12}>
                  <StatesConfigurationFormik />
                </Grid>
              </FeatureEnabled>
            ) : null}
            {isNotifications2Enabled && (
              <div className="mb-16">
                <RuleNotificationConfigurationFieldsFormik />
              </div>
            )}
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Select Entity
            </Text>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={4}>
                <TextFieldFormik
                  id="scope"
                  label="Entity"
                  disabled={isSubmitting}
                  className={classes.formTextField}
                  fullWidth
                  select
                >
                  {Object.keys(NOTIFICATIONS_SCOPES).map((entity) => (
                    <MenuItem key={entity} value={entity}>
                      {NOTIFICATIONS_SCOPES[entity]['desc']}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={4}>
                <TextFieldFormik
                  id="scope_status"
                  label="Entity Status"
                  disabled={isSubmitting}
                  className={classes.formTextField}
                  fullWidth
                  select
                >
                  {Object.keys(NOTIFICATIONS_ENTITY_STATUSES).map((entity) => (
                    <MenuItem key={entity} value={entity}>
                      {NOTIFICATIONS_ENTITY_STATUSES[entity]['desc']}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={4}>
                <MultiSelectTextFieldFormik
                  id="scope_types"
                  label="Entity Type"
                  disabled={isSubmitting || shouldDisableScopeTypesSelection}
                  options={scopeTypesOptions[values.scope] || []}
                  renderValue={() => ''}
                  renderOption={(value) => value?.desc || ''}
                  className={classes.formTextFieldNoErrorSpacing}
                  withOptionChips
                  fullWidth
                />
                {shouldDisableScopeTypesSelection && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningIcon size={24} iconColor={theme.palette.warning.main} />
                    <Typography
                      variant="body2"
                      style={{ fontSize: '14px', marginLeft: '5px', color: '#606060' }}
                    >{`Choose a specific ${
                      organization.sub_organizations_enabled ? 'sub org,' : ''
                    } lob and entity to enable selection`}</Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Details">
            <Grid item xs={12}>
              <div>
                <TextFieldFormik
                  id="rule_name"
                  label="Rule Name"
                  disabled={isSubmitting}
                  className={classes.formTextField}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={6} />
            <Grid container direction="row" spacing={3}>
              <Grid item xs={4}>
                <TextFieldFormik
                  id="recipient"
                  label="Recipient"
                  disabled={isSubmitting}
                  className={classes.formTextField}
                  fullWidth
                  select
                >
                  {Object.keys(recipientTypes)
                    .filter(
                      (recipient) =>
                        !recipientTypes[recipient].scope || recipientTypes[recipient].scope === values.scope
                    )
                    .map((entity) => (
                      <MenuItem key={entity} value={entity}>
                        {recipientTypes[entity]['desc']}
                      </MenuItem>
                    ))}
                </TextFieldFormik>
              </Grid>
              <Grid item xs={4}>
                {values.recipient === 'organization_unit_leader' && (
                  <AutocompleteFormik
                    id="organization_unit_id"
                    label="Organization Unit"
                    options={Object.keys(organizationUnitsById)}
                    getOptionLabel={getUnitName}
                    showOnly={isUnitsLoading || isUnitsError}
                    sortAlphabetic
                    disabled={isSubmitting || isUnitsLoading || isUnitsError}
                    className={classes.formTextField}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card title="Conditions" cardType={Card.CARD_TYPES.SECONDARY}>
            <div className={cn('mb-12 flex flex-grow flex-row space-x-16')}>
              <Grid item xs={4} className={cn('mb-12')}>
                <AutocompleteFormik
                  id="event_key"
                  label="Event"
                  options={filteredEvents}
                  getOptionLabel={(event) => event.desc}
                  sortAlphabetic
                  disabled={isSubmitting}
                  className={classes.formTextField}
                  fullWidth
                />
              </Grid>
              {eventTypeOptions.length > 0 && (
                <Grid item xs={3}>
                  <MultiSelectTextFieldFormik
                    id="event_criteria_types"
                    label="Type"
                    disabled={isSubmitting}
                    options={eventTypeOptions}
                    renderValue={(value) => value.key}
                    renderOption={(value) => value.desc}
                    className={classes.formTextFieldNoErrorSpacing}
                    fullWidth
                    withOptionChips
                    sortAlphabetic
                  />
                </Grid>
              )}
            </div>
            <div className={cn('mb-24 flex flex-grow flex-row gap-16')}>
              <EventScheduleSelect selectedEventConfig={selectedEventConfig} isSubmitting={isSubmitting} />
            </div>
            <ConditionsFormik conditions={conditions} disabled={isConditionsFormikDisabled} />
            <Grid item xs={12}>
              <TextAreaWithTokens
                id="title"
                label="Notification Title"
                disabled={isSubmitting}
                charsLimit={100}
                tokensList={relevantTokens}
                onAddToken={() =>
                  mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.ADD_NOTIFICATION_TOKEN_CLICKED, {
                    source: NOTIFICATION_MIXPANEL_EVENT_SOURCES.NOTIFICATIONS,
                  })
                }
                onChooseToken={(tokenKey) => {
                  mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.NOTIFICATION_TOKEN_VALUE_CHOSEN, {
                    source: NOTIFICATION_MIXPANEL_EVENT_SOURCES.NOTIFICATIONS,
                    value: tokenKey,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextAreaWithTokens
                id="content"
                label="Notification Text"
                disabled={isSubmitting}
                charsLimit={250}
                tokensList={relevantTokens}
                onAddToken={() =>
                  mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.ADD_NOTIFICATION_TOKEN_CLICKED, {
                    source: NOTIFICATION_MIXPANEL_EVENT_SOURCES.NOTIFICATIONS,
                  })
                }
                onChooseToken={(tokenKey) => {
                  mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.NOTIFICATION_TOKEN_VALUE_CHOSEN, {
                    source: NOTIFICATION_MIXPANEL_EVENT_SOURCES.NOTIFICATIONS,
                    value: tokenKey,
                  });
                }}
              />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </CardDialog>
  );
};

NotificationDialogInner.propTypes = {
  rule: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  subOrgIdToName: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  conditions: PropTypes.object.isRequired,
};
