import React from 'react';

import { SortableTable } from '~/components/core';
import Switch from '~/components/core/Atomic/Switch';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { ArrowDownwardIcon, ArrowUpwardIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import type { NotificationKanbanCategory } from '~/components/Notifications/Kanban/types';
import useOrganization from '~/components/OrganizationContext';
import ColoredDot from '~/components/SystemConfiguration/NotificationsConfiguration/ColoredDot';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';

interface KanbanConfigurationTableProps {
  categories?: NotificationKanbanCategory[];
  isError: boolean;
  isLoading: boolean;
  onMoveUp: (category: NotificationKanbanCategory) => void | Promise<void>;
  onMoveDown: (category: NotificationKanbanCategory) => void | Promise<void>;
  onShowToggle: (category: NotificationKanbanCategory) => void | Promise<void>;
}

const KanbanConfigurationTable: React.FC<KanbanConfigurationTableProps> = ({
  categories,
  isError = false,
  isLoading = false,
  onMoveUp,
  onMoveDown,
  onShowToggle,
}) => {
  const { organizationCategories } = useOrganization() as { organizationCategories: CategoryModel[] };
  const getNotificationCategory = (categoryId: number) => {
    return organizationCategories.find((category) => category.id === categoryId);
  };

  const columns = [
    {
      id: 'order',
      disableSort: true,
      label: 'Display Order',
      width: '120px',
      specialCell: (category: NotificationKanbanCategory) => (
        <div className="flex items-center">
          <span className="mr-12">
            <InlineIconButton
              icon={ArrowUpwardIcon}
              className="hover:cursor-pointer"
              onClick={() => onMoveUp(category)}
              disabled={category.kanban_column_index === categories?.[0].kanban_column_index}
              defaultColor={undefined}
              iconStyle={undefined}
              tooltipTitle={undefined}
              useIconButton={undefined}
              wrapWithSpan={undefined}
              ignorePermissions={undefined}
              ariaLabel={undefined}
            />
          </span>
          <InlineIconButton
            icon={ArrowDownwardIcon}
            className="hover:cursor-pointer"
            onClick={() => onMoveDown(category)}
            disabled={category.kanban_column_index === categories?.[categories.length - 1].kanban_column_index}
            defaultColor={undefined}
            iconStyle={undefined}
            tooltipTitle={undefined}
            useIconButton={undefined}
            wrapWithSpan={undefined}
            ignorePermissions={undefined}
            ariaLabel={undefined}
          />
        </div>
      ),
    },
    {
      id: 'color',
      label: 'Color',
      disableSort: true,
      specialCell: (category: NotificationKanbanCategory) => {
        const color = getNotificationCategory(category.category_id)?.color;
        return <ColoredDot color={color || 'grey-600'} />;
      },
    },
    {
      id: 'category_name',
      label: 'Category Name',
      specialCell: (category: NotificationKanbanCategory) => getNotificationCategory(category.category_id)?.label,
    },
    {
      id: 'is_enabled',
      label: 'Show',
      specialCell: (category: NotificationKanbanCategory) => (
        <Switch checked={category.is_enabled} disabled={false} onChange={() => onShowToggle(category)} />
      ),
    },
  ];

  return (
    <>
      {categories && (
        <SortableTable
          columns={columns}
          rows={categories.map((category) => ({ ...category, id: category.category_id }))}
        />
      )}
      {(isError || isLoading) && <SkeletonTable rowsCount={4} columnsCount={10} isError={isError} />}
    </>
  );
};

export default KanbanConfigurationTable;
