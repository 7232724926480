import React, { useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';

import CardDialog from '~/components/CardDialog';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { Card } from '~/components/core/Cards/Card';
import { NumericTextField } from '~/components/core/Molecules/Fields/MonetaryValueTextField';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import type {
  NotificationKanbanCategory,
  NotificationKanbanConfiguration,
} from '~/components/Notifications/Kanban/types';
import KanbanConfigurationTable from '~/components/SystemConfiguration/NotificationsConfiguration/KanbanConfiguration/KanbanConfigurationTable';
import { useKanbanConfigurationRoutes } from '~/components/SystemConfiguration/NotificationsConfiguration/KanbanConfiguration/useKanbanConfigurationRoutes';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

export const KanbanConfiguration: React.FC = () => {
  const { getBaseUrl, getMoveCategoryUpUrl, getMoveCategoryDownUrl, getDisableCategoryUrl, getEnableCategoryUrl } =
    useKanbanConfigurationRoutes();

  const {
    data: notificationKanbanConfiguration,
    isLoading,
    isError,
    reloadData,
  } = useDataFetcher(getBaseUrl()) as {
    isLoading: boolean;
    isError: boolean;
    data: NotificationKanbanConfiguration | undefined;
    reloadData: () => Promise<void>;
  };

  const onMoveUpHandler = useCallback(
    async (category: NotificationKanbanCategory) => {
      try {
        await axios.put(getMoveCategoryUpUrl(category.category_id), {});
        reloadData();
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [getMoveCategoryUpUrl, reloadData]
  );

  const onMoveDownHandler = useCallback(
    async (category: NotificationKanbanCategory) => {
      try {
        await axios.put(getMoveCategoryDownUrl(category.category_id), {});
        reloadData();
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [getMoveCategoryDownUrl, reloadData]
  );

  const onShowToggleHandler = useCallback(
    async (category: NotificationKanbanCategory) => {
      const url = category.is_enabled
        ? getDisableCategoryUrl(category.category_id)
        : getEnableCategoryUrl(category.category_id);
      try {
        await axios.put(url, {});
        reloadData();
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [getDisableCategoryUrl, getEnableCategoryUrl, reloadData]
  );

  const onSortChangeHandler = useCallback(
    async (selectedColumnSort) => {
      try {
        await axios.put(getBaseUrl(), {
          default_column_sort_by_type: selectedColumnSort,
          default_max_notification_per_category: null,
        });
        reloadData();
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [reloadData, getBaseUrl]
  );

  const onMaxCardsChangeHandler = useCallback(
    async (val) => {
      if (val > 100 || val < 1) {
        reloadData();
        return;
      }
      try {
        await axios.put(getBaseUrl(), {
          default_column_sort_by_type: null,
          default_max_notification_per_category: val,
        });
        reloadData();
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [reloadData, getBaseUrl]
  );

  return (
    <CardDialog
      title="Grouping"
      subheader="Mange and configure which categories to display in your board and their order"
    >
      <div className="flex flex-col gap-12">
        <KanbanConfigurationTable
          categories={notificationKanbanConfiguration?.categories}
          isLoading={isLoading}
          isError={isError}
          onMoveUp={onMoveUpHandler}
          onMoveDown={onMoveDownHandler}
          onShowToggle={onShowToggleHandler}
        />
        <Card title="Sort">
          <div className="flex flex-col gap-12">
            <div>Define the order in which cards are displayed on the board.</div>

            <SingleSelectField
              fullWidth
              variant="outlined"
              className="w-1/6 min-w-80"
              value={notificationKanbanConfiguration?.default_column_sort_by_type || 'priority'}
              onChange={(val) => onSortChangeHandler(val)}
            >
              <MenuItem key="priority" value="priority">
                Priority
              </MenuItem>
              <MenuItem key="due_date" value="due_date">
                Due Date
              </MenuItem>
            </SingleSelectField>
          </div>
        </Card>
        <Card title="Max Cards">
          <div className="flex flex-col gap-12">
            <div>
              Set the default max number of cards to be displayed in each group, if the cards surpass that number,
              they’ll be accessible by clicking on a “show more” button.
            </div>
            <NumericTextField
              id="max-cards"
              label="Max Cards"
              className="w-80"
              displayType="input"
              allowedDecimalSeparators={false}
              value={notificationKanbanConfiguration?.categories[0].max_notification_per_category}
              onChange={debounce(onMaxCardsChangeHandler, 800)}
              max={100}
              min={1}
            />
          </div>
        </Card>
      </div>
    </CardDialog>
  );
};
