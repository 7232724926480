import React from 'react';

import ConfigurationScreenWithTabs from '~/components/SystemConfiguration/ConfigurationScreenWithTabs';
import { KanbanConfiguration } from '~/components/SystemConfiguration/NotificationsConfiguration/KanbanConfiguration/KanbanConfiguration';
import NotificationsCategories from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import NotificationsPreferences from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsPreferences';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { ADMIN_ROUTES } from '~/routesConstants';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

const NotificationsConfiguration: React.FC = () => {
  const { organization } = useSysconfig() as { organization: { id: number } };
  const isNotificationKanbanEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.NOTIFICATION_KANBAN_BOARD
  );
  const tabs = [
    {
      label: 'Categories',
      url: ADMIN_ROUTES.NOTIFICATIONS_CONFIGURATION.CATEGORIES,
      component: NotificationsCategories,
      isEnabled: true,
    },
    {
      label: 'Preferences',
      url: ADMIN_ROUTES.NOTIFICATIONS_CONFIGURATION.PREFERENCES,
      component: NotificationsPreferences,
      isEnabled: true,
    },
    {
      label: 'Kanban Board',
      url: ADMIN_ROUTES.NOTIFICATIONS_CONFIGURATION.KANBAN,
      component: KanbanConfiguration,
      isEnabled: isNotificationKanbanEnabled,
    },
  ];

  return (
    <ConfigurationScreenWithTabs
      tabs={tabs}
      title="NOTIFICATIONS CONFIGURATION"
      subtitle="Configure notification categories and manage notification preferences"
      operationTabName="Notifications Configuration"
    />
  );
};

export default NotificationsConfiguration;
