import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import DocumentTemplatesInfoBanner from '~/components/GenericTemplates/GenericTemplatesConfiguration/DocumentTemplatesInfoBanner/DocumentTemplatesInfoBanner';
import GenericTemplateBuilderContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/GenericTemplateBuilderContainer';
import GenericTemplateCardContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateCardContainer';
import GenericTemplateAdvancedSearchContainerFormik from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateSearch/GenericTemplateAdvancedSearch/GenericTemplateAdvancedSearchContainerFormik';
import GenericTemplatesTable from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesTable';
import type {
  AdvancedSearchFilterTypes,
  GenericTemplatesRoutes,
  PaginationProps,
  TemplateFormSubmitValuesProps,
  TemplateTypeKey,
} from '~/components/GenericTemplates/types';
import { DEFAULT_TEMPLATES_PAGINATION_PROPS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { getAxiosParamsSerializer, reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import DocumentsTemplatesActions from './DocumentTemplateActions/DocumentTemplateActions';
import DocumentTemplateCardContainer from './GenericTemplateCard/DocumentTemplateCardContainer';

const FETCH_PARAMS_SERIALIZER = getAxiosParamsSerializer('none');

export interface GenericTemplatesAdditionalPropsType<T> {
  component: React.FC<T>;
  props: T;
  defaultFormValues?: TemplateFormSubmitValuesProps;
}

export interface GenericTemplatesTableContainerPropsType<T = unknown> {
  templateType: TemplateTypeKey;
  shouldDisplayType: boolean;
  isCreateTemplateEnabled: boolean;
  templateRoutesDict?: GenericTemplatesRoutes;
  additionalProps?: GenericTemplatesAdditionalPropsType<T>;
}

const GenericTemplatesTableContainer: React.FC<GenericTemplatesTableContainerPropsType> = ({
  templateType,
  shouldDisplayType,
  isCreateTemplateEnabled,
  templateRoutesDict = GENERIC_TEMPLATES_ROUTES,
  additionalProps,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const [paginationProps, setPaginationProps] = useState(DEFAULT_TEMPLATES_PAGINATION_PROPS);
  const [templates, setTemplates] = useState([]);
  const [templatesCount, setTemplatesCount] = useState(0);
  const [searchParams, setSearchParams] = useState({
    template_type: templateType,
    template_name: '',
  });
  const [isCreateTemplateDialogOpen, setIsCreateTemplateDialogOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  const { data, isLoading, isError, reloadData } = useDataFetcher(templateRoutesDict.GET_TEMPLATES(organization.id), {
    params: {
      ...paginationProps,
      ...searchParams,
      page: paginationProps.page + 1,
    },
    paramsSerializer: FETCH_PARAMS_SERIALIZER,
  });

  const displayLoading = isLoading || isError;

  useEffect(() => {
    setTemplates(data?.templates_metadata);
    setTemplatesCount(data?.count);
  }, [data]);

  const isDocumentTemplate = templateType === 'document';

  const handleSelectTemplate = (template_id: number) => {
    setSelectedTemplateId(template_id);
  };

  const handleUpdatePaginationProps = (updatedPaginationProps: PaginationProps) => {
    setPaginationProps(updatedPaginationProps);
  };

  const handleAdvancedTemplatesSearch = (values: AdvancedSearchFilterTypes) => {
    setSearchParams({
      ...searchParams,
      ...DEFAULT_TEMPLATES_PAGINATION_PROPS,
      ...values,
      template_type: templateType,
    });
  };

  const handleOpenCreateTemplateDialog = () => {
    setIsCreateTemplateDialogOpen(true);
  };

  const handleCloseCreateTemplateDialog = () => {
    setIsCreateTemplateDialogOpen(false);
  };

  const handleSubmitTemplate = async (values: TemplateFormSubmitValuesProps) => {
    try {
      await axios.post(templateRoutesDict.CREATE_TEMPLATE(organization.id), values);
      await reloadData();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <div>
      <div className="m-12 grid grid-cols-2 justify-between p-12">
        {/* TODO: NGTPA-16665 - Add Import+Export templates */}
        <div />
        {isCreateTemplateEnabled ? (
          <div className="flex items-center justify-end">
            <Button color="primary" disabled={false} onClick={handleOpenCreateTemplateDialog}>
              <AddIcon />
              Add template
            </Button>
          </div>
        ) : null}
        {isDocumentTemplate ? (
          <div className="flex items-center justify-end">
            <DocumentsTemplatesActions
              organizationId={organization.id}
              reloadDataDocumentTemplates={reloadData}
              documentTemplates={templates}
            />
          </div>
        ) : null}
      </div>
      <GenericTemplateAdvancedSearchContainerFormik
        handleSubmit={handleAdvancedTemplatesSearch}
        isLoading={isLoading}
      />
      {isDocumentTemplate ? <DocumentTemplatesInfoBanner /> : null}
      <div className={cn('grid gap-4', selectedTemplateId ? 'grid-cols-12' : 'grid-cols-1')}>
        <div className="col-span-8">
          {displayLoading ? (
            <SkeletonTable rowsCount={10} columnsCount={10} isError={isError} />
          ) : (
            <GenericTemplatesTable
              templates={templates}
              templatesCount={templatesCount}
              onSelectTemplate={handleSelectTemplate}
              onUpdatePaginationProps={handleUpdatePaginationProps}
              paginationProps={paginationProps}
              shouldDisplayType={shouldDisplayType}
              selectedTemplateId={selectedTemplateId}
              reloadTable={reloadData}
              templateRoutesDict={templateRoutesDict}
            />
          )}
        </div>
        {selectedTemplateId ? (
          !isDocumentTemplate ? (
            <div className="col-span-4">
              <GenericTemplateCardContainer
                templateId={selectedTemplateId}
                reloadTable={reloadData}
                additionalProps={additionalProps}
              />
            </div>
          ) : (
            <div className="col-span-4">
              <DocumentTemplateCardContainer
                templateId={selectedTemplateId}
                reloadTable={reloadData}
                templatesMetadata={templates}
              />
            </div>
          )
        ) : null}
        {isCreateTemplateDialogOpen ? (
          <GenericTemplateBuilderContainer
            templateType={templateType}
            handleClose={handleCloseCreateTemplateDialog}
            handleSubmit={handleSubmitTemplate}
            additionalProps={additionalProps}
          />
        ) : null}
      </div>
    </div>
  );
};

export default GenericTemplatesTableContainer;
