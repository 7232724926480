import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { isNil } from 'lodash';

import MenuItem from '~/components/core/Atomic/MenuItem';
import type { NotificationConfigurationFormikFields } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/types';
import { NumericTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';
import { NOTIFICATIONS_SCHEDULES } from '~/Types';

interface TimeframeFieldSelectProps {
  isSubmitting: boolean;
  immediate_or_custom_field?: string; // todo remove this and just use isDueDateImmediateField https://fivesigma.atlassian.net/browse/NGTPA-18577
  isDueDateImmediateField?: string;
  number_field: string;
  timeframe_field: string;
  forceScheduleFields?: boolean;
  timeframeOptions: { key: string; label: string }[];
  label: string;
}

const TimeframeFieldSelect: React.FC<TimeframeFieldSelectProps> = ({
  isSubmitting,
  isDueDateImmediateField,
  immediate_or_custom_field,
  number_field,
  timeframe_field,
  forceScheduleFields,
  timeframeOptions,
  label,
}) => {
  // todo remove immediate_or_custom_field https://fivesigma.atlassian.net/browse/NGTPA-18577
  // no reason to have immediate_or_custom_field logic so I added support for is_due_date_immediate in addition
  // and then we can remove immediate_or_custom_field logic later
  if (isDueDateImmediateField && immediate_or_custom_field) {
    throw new Error(`TimeframeFieldSelect can't have both is_due_date_immediate_field and immediate_or_custom_field`);
  }
  const { values, setFieldValue } = useFormikContext<NotificationConfigurationFormikFields>();
  let scheduleField: 'immediate' | 'custom' = 'immediate';
  if (isDueDateImmediateField) {
    const isDueDateImmediate = getIn(values, isDueDateImmediateField);
    if (!isNil(isDueDateImmediate)) {
      scheduleField = isDueDateImmediate ? 'immediate' : 'custom';
    }
  } else if (immediate_or_custom_field) {
    scheduleField = getIn(values, immediate_or_custom_field);
  }

  const handleChangeImmediateOrCustom = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isDueDateImmediateField) {
      setFieldValue(isDueDateImmediateField, event.target.value === 'immediate');
    } else if (immediate_or_custom_field) {
      setFieldValue(immediate_or_custom_field, event.target.value);
    }
  };

  const shouldShowScheduleFields =
    NOTIFICATIONS_SCHEDULES[scheduleField]?.should_show_schedule_fields || forceScheduleFields;

  return (
    <div className="grid grid-cols-12 gap-8">
      {!forceScheduleFields ? (
        <div className="col-span-2">
          <TextFieldFormik
            id={immediate_or_custom_field ?? 'timeFrameFieldId'}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={handleChangeImmediateOrCustom}
            label={label}
            disabled={isSubmitting}
            className="my-4"
            fullWidth
            select
            value={scheduleField}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            {Object.keys(NOTIFICATIONS_SCHEDULES).map((option: keyof typeof NOTIFICATIONS_SCHEDULES) => (
              <MenuItem key={option} value={option}>
                {NOTIFICATIONS_SCHEDULES[option].desc}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      ) : null}
      {shouldShowScheduleFields && (
        <NumericTextFieldFormik id={number_field} label="Number" disabled={isSubmitting} className="my-4" fullWidth />
      )}
      {shouldShowScheduleFields && (
        <div className="col-span-2">
          <TextFieldFormik
            id={timeframe_field}
            label="Time Frame"
            disabled={isSubmitting}
            className="my-4"
            fullWidth
            select
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            {timeframeOptions.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      )}
    </div>
  );
};

export default TimeframeFieldSelect;
