import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import ThreeDotsMenu from '../../../../core/ThreeDotsMenu';
import copies from '../copies.json';
import FieldConfigurationCard from '../FieldConfigurationCard/FieldConfigurationCard';
import MoveArrows from '../MoveArrows/MoveArrows';

import BaseConfigurationSection from './BaseConfigurationSection';

const SECTIONS_SPECIFIC_ID = 'configuration.incident_details.configured_fields';
const CONFIGURED_FIELD_TEMPLATE = {
  id: '',
  desc: '',
  type: '',
  active: true,
  mandatory: false,
};

const getNewField = ({ index, section = undefined }) => ({
  ...CONFIGURED_FIELD_TEMPLATE,
  id: `configured_field_${index}`,
  desc: `Configured Field ${index}`,
  section,
});

const getMoveSectionText = ({ configuredFields, index }) => {
  const isSupplementary = configuredFields?.[index]?.section === 'supplementary_info';
  return `Move to ${!isSupplementary ? 'Supplementary Information' : 'Incident Details'} Section`;
};

const ConfiguredFieldsSection = ({
  fieldId,
  section,
  disabled = false,
  disableCategories = false,
  subtitle = copies.additional_fields_note,
  disabledWithoutPadding = false,
  disabledWithoutChevron = false,
  withoutRequiredForClaimClosing = false,
  requiredLabel,
}) => {
  const { values, initialValues, setFieldValue } = useFormikContext();

  const initialConfiguredFields = get(initialValues, fieldId);
  const configuredFields = get(values, fieldId, []);

  const sectionIndexes =
    section === 'supplementary_info'
      ? (configuredFields || [])
          .map((field, index) => (field?.section === 'supplementary_info' ? index : undefined))
          .filter((index) => index !== undefined)
      : (configuredFields || [])
          .map((field, index) => (field?.section === 'incident_details' || !field?.section ? index : undefined))
          .filter((index) => index !== undefined);

  const isDeleteAllowed = (index) => !initialConfiguredFields?.find((f) => f.id === configuredFields?.[index]?.id);
  const additionalFieldsCount = sectionIndexes?.length || 0;

  return (
    <BaseConfigurationSection
      title={`Additional Fields (${additionalFieldsCount})`}
      subtitle={subtitle}
      noGridContainer
      disabled={disabled}
      withActionsContainerFullWidth={false}
      disabledWithoutPadding={disabledWithoutPadding}
      disabledWithoutChevron={disabledWithoutChevron}
    >
      <FieldArray
        name={fieldId}
        render={(arrayHelpers) => (
          <div className="mr-6 flex flex-col gap-12">
            {Array.isArray(sectionIndexes) &&
              sectionIndexes.map((originalArrayIndex, idx) => (
                <div className="row-auto flex w-full justify-between" key={originalArrayIndex}>
                  <FieldConfigurationCard
                    fieldId={`${fieldId}[${originalArrayIndex}]`}
                    isConfiguredField
                    withoutRequiredForClaimClosing={withoutRequiredForClaimClosing}
                    requiredLabel={requiredLabel}
                    actions={
                      <div className="flex items-center justify-center">
                        <MoveArrows
                          onMoveUp={() => {
                            arrayHelpers.move(originalArrayIndex, sectionIndexes[Math.max(idx - 1, 0)]);
                          }}
                          onMoveDown={() => {
                            arrayHelpers.move(
                              originalArrayIndex,
                              sectionIndexes[Math.min(idx + 1, sectionIndexes?.length - 1)]
                            );
                          }}
                          disabled={disabled}
                        />
                        <ThreeDotsMenu
                          stopPropagation
                          disabled={fieldId !== SECTIONS_SPECIFIC_ID && !isDeleteAllowed(originalArrayIndex)}
                          options={[
                            ...(fieldId !== SECTIONS_SPECIFIC_ID
                              ? []
                              : [
                                  {
                                    onClick: () => {
                                      setFieldValue(
                                        `${fieldId}[${originalArrayIndex}].section`,
                                        section === 'supplementary_info' ? 'incident_details' : 'supplementary_info'
                                      );
                                    },
                                    node: getMoveSectionText({ configuredFields, index: originalArrayIndex }),
                                    key: 'moveSection',
                                    disabled: fieldId !== SECTIONS_SPECIFIC_ID,
                                  },
                                ]),
                            {
                              onClick: () => arrayHelpers.remove(originalArrayIndex),
                              node: 'Remove',
                              key: 'remove',
                              disabled: !isDeleteAllowed(originalArrayIndex),
                            },
                          ]}
                        />
                      </div>
                    }
                    disableCategories={disableCategories}
                  />
                </div>
              ))}
            <ErrorHelperTextFormik id={fieldId} withoutChildren />
            <div className="flex">
              <Button
                variant="text"
                color="primary"
                onClick={() => arrayHelpers.push(getNewField({ index: configuredFields?.length + 1, section }))}
                disabled={disabled}
              >
                <AddIcon />
                ADD AN ADDITIONAL FIELD
              </Button>
            </div>
          </div>
        )}
      />
    </BaseConfigurationSection>
  );
};

ConfiguredFieldsSection.propTypes = {
  fieldId: PropTypes.string.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool,
  disableCategories: PropTypes.bool,
  subtitle: PropTypes.string,
  disabledWithoutPadding: PropTypes.bool,
  disabledWithoutChevron: PropTypes.bool,
  withoutRequiredForClaimClosing: PropTypes.bool,
  requiredLabel: PropTypes.string,
};

export default ConfiguredFieldsSection;
