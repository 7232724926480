import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Typography from '~/components/core/Atomic/Typography';

import { COUNTRIES_DICT, GLOBAL_ADDRESSES_OPTIONS, LOCATION_TYPES_DICT } from '../../../../../Types';
import { SortableTable } from '../../../../core';
import { PencilIcon } from '../../../../icons';
import propTypes from '../propTypes';

import EditCountryLocationDialog from './EditCountryLocationDialog';

import { useStyles } from '../../../../../assets/styles';

const LOCATION_TYPES_KEYS = Object.keys(LOCATION_TYPES_DICT);

const LocationTable = ({ supportedLocations = [], onUpdate = noop }) => {
  const classes = useStyles();
  const [editConfigDialogOpen, setEditConfigDialogOpen] = useState(false);
  const [supportedLocationToEdit, setSupportedLocationToEdit] = useState();
  const columns = [
    {
      id: 'location_type',
      label: 'Location Type',
      specialCell: (supportedLocation) => (
        <Typography variant="subtitle2">{LOCATION_TYPES_DICT[supportedLocation.location_type]}</Typography>
      ),
    },
    {
      id: 'configuration_select',
      label: 'Geographic Coverages',
      specialCell: (supportedLocation) => GLOBAL_ADDRESSES_OPTIONS[supportedLocation.configuration_select],
    },
    {
      id: 'selected_countries',
      label: 'Multiple Countries',
      specialCell: (supportedLocation) => {
        return supportedLocation.configuration_select === 'multiple'
          ? supportedLocation.selected_countries.map((countryCode) => COUNTRIES_DICT[countryCode]).join(', ')
          : null;
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSort: true,
      specialCell: (supportedLocation) => (
        <PencilIcon className={classes.hoverableNonFilledIcon} onClick={() => onOpenDialog(supportedLocation)} />
      ),
    },
  ];

  const onOpenDialog = (supportedLocation) => {
    setSupportedLocationToEdit(supportedLocation);
    setEditConfigDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSupportedLocationToEdit(undefined);
    setEditConfigDialogOpen(false);
  };

  const handleCountryLocationsUpdate = async (values) => {
    await onUpdate(values);
    handleCloseDialog();
  };
  const sortedSupportedLocation = supportedLocations
    .sort((a, b) => LOCATION_TYPES_KEYS.indexOf(a.location_type) - LOCATION_TYPES_KEYS.indexOf(b.location_type))
    .map((data) => ({ ...data, id: data.location_type }));

  return (
    <>
      {editConfigDialogOpen && (
        <EditCountryLocationDialog
          supportedLocation={supportedLocationToEdit}
          onClose={handleCloseDialog}
          onUpdate={handleCountryLocationsUpdate}
        />
      )}
      <SortableTable rows={sortedSupportedLocation} columns={columns} order="desc" keepRowsOrder />
    </>
  );
};

LocationTable.propTypes = {
  supportedLocations: PropTypes.arrayOf(PropTypes.shape(propTypes.countriesConfigurationForLocation)),
  onUpdate: PropTypes.func,
};

export default LocationTable;
