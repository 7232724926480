import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import TextAreaWithTokens from '~/components/core/Formik/TextAreaWithTokens';

import TextFieldFormik from '../../../../TextFieldFormik';

import VoiceFileUploaderFormik from './VoiceFileUploaderFormik';

import styles from './voiceMessageConfiguration.module.scss';

const VoiceMessageConfigurationDialog = ({
  title,
  voiceMessageConfiguration,
  allowedTokens,
  textAreaLabel,
  textAreaId,
  voiceFileInputId,
  radioButtonGroupId,
  onReplaceExistingRecording,
}) => {
  const { values, setErrors } = useFormikContext();

  return (
    <div className={styles.container}>
      {title ? (
        <Typography variant="subtitle1" className={styles.title}>
          {title}
        </Typography>
      ) : null}
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioButtonGroupFormik
              id={radioButtonGroupId}
              direction="row"
              options={[
                { text: 'Add text message', optionValue: 'text_message' },
                {
                  text: 'Upload voice message',
                  optionValue: 'uploaded_text_message',
                },
              ]}
              variant="subtitle1"
              onClick={() => {
                setErrors([]);
              }}
            />
          </Grid>
        </Grid>
        <div className={styles.inputsContainer}>
          {values[radioButtonGroupId] === 'text_message' ? (
            allowedTokens ? (
              <TextAreaWithTokens
                charsLimit={null}
                id={textAreaId}
                label={textAreaLabel}
                onAddToken={function noRefCheck() {}}
                onChooseToken={function noRefCheck() {}}
                tokensList={allowedTokens}
              />
            ) : (
              <TextFieldFormik id={textAreaId} label={textAreaLabel} variant="filled" fullWidth multiline rows={4} />
            )
          ) : null}

          {values[radioButtonGroupId] === 'uploaded_text_message' ? (
            <VoiceFileUploaderFormik
              voiceFileInputId={voiceFileInputId}
              existingVoiceFileUrl={voiceMessageConfiguration?.permanent_audio_file_url}
              onReplaceExistingRecording={onReplaceExistingRecording}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

VoiceMessageConfigurationDialog.propTypes = {
  title: PropTypes.string,
  voiceMessageConfiguration: PropTypes.object.isRequired,
  allowedTokens: PropTypes.objectOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
    }).isRequired
  ),
  textAreaLabel: PropTypes.string.isRequired,
  textAreaId: PropTypes.string.isRequired,
  radioButtonGroupId: PropTypes.string.isRequired,
  voiceFileInputId: PropTypes.string.isRequired,
  onReplaceExistingRecording: PropTypes.func.isRequired,
};

export default VoiceMessageConfigurationDialog;
