import React from 'react';
import { useFormikContext } from 'formik';

import PriorityCategoryFieldsFormikInner from '~/components/SystemConfiguration/NotificationUtils/PriorityCategoryFIeldsFormikInner';
import TimeframeFieldSelect from '~/components/SystemConfiguration/NotificationUtils/TimeframeFieldSelect';
import { NOTIFICATIONS_RULES_TIMEFRAMES } from '~/Types';

export interface NotificationConfigurationFieldsFormikInnerProps {
  isDueDateImmediateField?: string;
}
const NotificationConfigurationFieldsFormikInner: React.FC<NotificationConfigurationFieldsFormikInnerProps> = ({
  isDueDateImmediateField,
}) => {
  const { isSubmitting } = useFormikContext();

  const timeframeOptions = Object.entries(NOTIFICATIONS_RULES_TIMEFRAMES)
    .filter(([key]) => key !== 'hours')
    .map(([key, option]) => ({
      key,
      label: option.desc,
    }));

  return (
    <div>
      <PriorityCategoryFieldsFormikInner />
      <div className="grid grid-cols-2 gap-8">
        <div className="col-span-2">
          <TimeframeFieldSelect
            isSubmitting={isSubmitting}
            timeframeOptions={timeframeOptions}
            immediate_or_custom_field={isDueDateImmediateField ? undefined : 'due_date_immediate_or_custom'}
            isDueDateImmediateField={isDueDateImmediateField}
            number_field="due_date_number"
            timeframe_field="due_date_timeframe"
            label="Due Date"
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationConfigurationFieldsFormikInner;
