import React from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn, useFormikContext } from 'formik';
import { snakeCase, uniq } from 'lodash';
import * as Yup from 'yup';

import WithConfirm from '~/components/ConfirmModal';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import RadioWithButtonWrapperFormik from '~/components/core/Formik/RadioWithButtonWrapperFormik';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import DAMAGE_ASSESSMENT_TYPES from '~/server_shared/generated-types/DAMAGE_ASSESSMENT_TYPES';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

import {
  CONFIGURATION_FEATURES_NAMES,
  COVERAGES_ISO_CODES,
  INVOLVED_DICT_PER_CLAIM_TYPE,
  LOSS_TYPES,
} from '../../../Types';
import { isFeatureEnabled, subOrgIdToNameDict } from '../../../Utils';
import { getLobDescription, getLobIcon } from '../../../Utils/lobUtils';
import AutocompleteFormik from '../../AutocompleteFormik';
import CardDialog from '../../CardDialog';
import { TooltipIcon } from '../../core';
import { useShouldDisableFormikField } from '../../hooks/useShouldDisableFormikField';
import { InfoIcon } from '../../icons';
import { MultiSelectTextFieldFormik, TextFieldFormik, useSetDefaultFieldsOnChange } from '../../TextFieldFormik';
import LobRadioFormik from '../../TPA/LOB/LobRadioFormik';
import useDataFetcher from '../../useDataFetcher';
import { SubReservesTypesDialogInner } from '../Tabs/PaymentsConfiguration/PaymentConfigTab/SubReservesTypesDialog/SubReservesTypesDialog';
import {
  getPreProcessedSubReservesBeforeSubmit,
  getPreProcessSubReservesConfigArray,
  getSubReservesTypesNestedScheme,
} from '../Tabs/PaymentsConfiguration/PaymentConfigTab/SubReservesTypesDialog/subReservesUtils';

import { NoAvailableLobs, useLobsSubOrgsIntersectionCheck } from './ExposureConfigurationUtils';

import { colorPalette, useStyles } from '../../../assets/styles';
import styles from './coverageDialog.module.scss';

export const CoverageDialog = ({
  coverage,
  onSubmit,
  onClose,
  organization,
  supportedClaimTypes,
  fetchUsedCoveragesKeys,
  errorHandler,
  wizardLob,
  overrideDisabled,
}) => {
  return (
    <Formik
      initialValues={{
        display_name: coverage?.display_name || '',
        coverage_key: coverage?.coverage_key || '',
        iso_code: coverage?.iso_code,
        damage_assessment_type: coverage?.damage_assessment_type || 'default',
        sub_organization_ids: coverage?.sub_organization_ids || [],
        lob: coverage?.lob || supportedClaimTypes[0],
        loss_type: coverage?.loss_type || '',
        involved_types: coverage?.involved_types || [],
        is_limit_per_person: coverage?.is_limit_per_person || false,
        is_limit_per_incident: coverage?.is_limit_per_incident || false,
        is_deductible: coverage?.is_deductible || false,
        is_coinsurance: coverage?.is_coinsurance || false,
        is_limit_per_policy_per_entity: coverage?.is_limit_per_policy_per_entity || false,
        should_include_dates_of_service: coverage?.should_include_dates_of_service || false,
        use_indemnity_sub_reserves: coverage?.is_sub_reserves_config_enabled || false,
        sub_reserves_config: getPreProcessSubReservesConfigArray(coverage?.sub_reserves_config ?? {}),
        coverage_group_id: coverage?.coverage_group_id
          ? { id: coverage.coverage_group_id, display_name: coverage.coverage_group_display_name }
          : null,
      }}
      validationSchema={Yup.object().shape({
        display_name: Yup.string().max(50).required('Required'),
        coverage_key: Yup.string()
          .max(50)
          .required('Required')
          .test('coverage_key_unique', 'Must be unique', async function (value) {
            const keysUsedRet = await fetchUsedCoveragesKeys({
              coverage_config_ids_to_ignore: coverage ? [coverage.id] : undefined,
            });

            return !keysUsedRet.data.includes(value);
          }),
        iso_code: Yup.string()
          .nullable()
          .oneOf([null].concat(Object.keys(COVERAGES_ISO_CODES)), 'Unknown ISO code'),
        damage_assessment_type: Yup.string()
          .nullable()
          .oneOf([null].concat(Object.keys(DAMAGE_ASSESSMENT_TYPES)), 'Unknown damage assessment type'),
        sub_organization_ids: Yup.array().test('sub_orgs_ids', 'Required', function (value) {
          return organization.sub_organizations_enabled ? value.length > 0 : true;
        }),
        lob: Yup.string().required('Required').oneOf(supportedClaimTypes, 'Invalid line of business'),
        loss_type: Yup.string().required('Required').oneOf(Object.keys(LOSS_TYPES), 'Invalid Type of loss'),
        involved_types: Yup.array()
          .min(1, 'Required')
          .test(
            'unspecified_unique',
            'Involved entity can be either one unspecified OR multiple specified',
            function (value) {
              if (!this.parent.lob) {
                return true;
              }
              const involved_dict_by_lob = INVOLVED_DICT_PER_CLAIM_TYPE[this.parent.lob];
              if (value.length > 1) {
                return !value.some(
                  (val) => involved_dict_by_lob && involved_dict_by_lob[val] && involved_dict_by_lob[val].is_unspecified
                );
              }
              return true;
            }
          )
          .test(
            'unspecified_not_changed',
            'Involved entity can not be changed from unspecified to specified or vice versa',
            function (value) {
              if (coverage && this.parent.lob) {
                const involved_dict_by_lob = INVOLVED_DICT_PER_CLAIM_TYPE[this.parent.lob];
                const current_involved_types_contains_unspecified = coverage?.involved_types.some(
                  (val) => involved_dict_by_lob?.[val]?.is_unspecified
                );
                const new_involved_types_contains_unspecified = value.some(
                  (val) => involved_dict_by_lob?.[val]?.is_unspecified
                );
                return current_involved_types_contains_unspecified === new_involved_types_contains_unspecified;
              } else {
                return true;
              }
            }
          ),
        is_limit_per_person: Yup.boolean(),
        is_limit_per_incident: Yup.boolean(),
        is_deductible: Yup.boolean(),
        is_coinsurance: Yup.boolean(),
        is_limit_per_policy_per_entity: Yup.boolean(),
        should_include_dates_of_service: Yup.boolean(),
        use_indemnity_sub_reserves: Yup.boolean(),
        sub_reserves_config: Yup.array()
          .of(getSubReservesTypesNestedScheme())
          .when('use_indemnity_sub_reserves', {
            is: true,
            then: Yup.array().min(1, 'At least 1 is required'),
          })
          .test('unique-keys-and-desc', 'The keys and names have to be unique', (subReservesConfigs) => {
            const typeKeysArray = subReservesConfigs.map((subReservesConfig) => subReservesConfig.type_key);
            const typeKeysSet = new Set(typeKeysArray);

            const typeDescArray = subReservesConfigs.map((subReservesConfig) => subReservesConfig.desc);
            const typeDescSet = new Set(typeDescArray);

            return typeKeysArray.length === typeKeysSet.size && typeDescArray.length === typeDescSet.size;
          }),
      })}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const isAllSubOrgs = values.sub_organization_ids.indexOf('all') > -1;
          await onSubmit(
            {
              ...values,
              sub_organization_ids: isAllSubOrgs ? [] : values.sub_organization_ids,
              coverage_group_id: values?.coverage_group_id?.id,
              sub_reserves_config: getPreProcessedSubReservesBeforeSubmit(values['sub_reserves_config']),
            },
            coverage?.id
          );
          onClose();
        } catch (error) {
          await errorHandler(error);
          setSubmitting(false);
        }
      }}
    >
      <CoverageDialogInner
        organization={organization}
        coverage={coverage}
        onClose={onClose}
        wizardLob={wizardLob}
        overrideDisabled={overrideDisabled}
      />
    </Formik>
  );
};

CoverageDialog.propTypes = {
  coverage: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  supportedClaimTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchUsedCoveragesKeys: PropTypes.func.isRequired,
  errorHandler: PropTypes.func.isRequired,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
};

export const CoverageDialogInner = ({ coverage, onClose, organization, wizardLob, overrideDisabled }) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit, values, setFieldTouched, errors, touched, initialValues } = useFormikContext();
  const shouldShowISOCode = isLocaleRegionIsUs() || coverage?.iso_code;
  const subOrgIdToName = subOrgIdToNameDict(organization);
  const isAllSubOrgs = values.sub_organization_ids.indexOf('all') > -1;
  const { lobConfigurationsDict = {} } = useLobConfiguration();

  let involvedDict;
  // non-GC involveds exist only in old FNOL and auto_claim (no GC in old FNOL)
  if (!isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) || values?.lob === 'auto_claim') {
    involvedDict = INVOLVED_DICT_PER_CLAIM_TYPE[values?.lob] || {};
  } else {
    involvedDict = lobConfigurationsDict[values?.lob]?.involved_dict || {};
  }

  const possibleLossTypes = () =>
    values?.lob ? uniq(Object.values(involvedDict).map((involved) => involved?.loss_type || '')) : [];

  const involvedTypesOption = () =>
    values?.lob && values?.loss_type
      ? Object.entries(involvedDict)
          .filter(([_, configs]) => configs?.loss_type === values.loss_type)
          .map(([involved]) => involved || '')
      : [];

  const involvedTypesRenderOption = (option) => {
    return involvedDict && Object.keys(involvedDict).includes(option) ? involvedDict[option]?.desc || '' : '';
  };

  useSetDefaultFieldsOnChange(
    values.lob,
    {
      involved_types: [],
    },
    () => ['involved_types'].forEach((field) => setFieldTouched(field, false))
  );

  useSetDefaultFieldsOnChange(values.loss_type, {
    involved_types: [],
  });

  const { filterLobsFunction, availableLobsForSubOrgs } = useLobsSubOrgsIntersectionCheck(organization);
  useSetDefaultFieldsOnChange(
    values.display_name,
    {
      coverage_key: snakeCase(values.display_name),
    },
    () => ['coverage_key'].forEach((field) => setFieldTouched(field, true)),
    !!coverage
  );

  const shouldDisableFieldFunction = useShouldDisableFormikField(overrideDisabled);
  const subReservesChangedToHidden = initialValues.sub_reserves_config
    ? initialValues.sub_reserves_config.filter((initialItem) => {
        const currentItem = values.sub_reserves_config.find((item) => item.type_key === initialItem.type_key);
        return currentItem && currentItem.is_hidden && currentItem.is_hidden !== initialItem.is_hidden;
      })
    : [];
  return (
    <CardDialog
      isDialog
      title={`${coverage ? 'Edit' : 'Add '} Coverage`}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions
          buttonsOverride={
            <>
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <WithConfirm
                title=""
                disableConfirm={subReservesChangedToHidden.length < 1}
                contentText={
                  <div className="p-16">
                    The following sub reserves where changed to hidden:{' '}
                    {subReservesChangedToHidden.map((s) => s.type_key).join(', ')}.
                    <p>Make sure that all usages of these sub reserves are set to 0.</p>
                    <p>Check usage in stat reserves and automatic exposures</p>
                  </div>
                }
                primaryButtonName="Save"
                maxWidth="md"
              >
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </WithConfirm>
            </>
          }
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NoAvailableLobs organization={organization} />
        </Grid>
        {organization.sub_organizations_enabled && (
          <Grid item xs={4}>
            <div>
              <Typography
                display="block"
                variant="subtitle2"
                style={{ fontWeight: 'bold', color: colorPalette.text.primary }}
              >
                Select Sub Organizations
              </Typography>
              <div>
                <MultiSelectTextFieldFormik
                  id="sub_organization_ids"
                  label="Sub Organizations"
                  disabled={shouldDisableFieldFunction(coverage)}
                  options={
                    isAllSubOrgs ? ['all'] : ['all'].concat(organization.sub_organizations.map((subOrg) => subOrg.id))
                  }
                  renderValue={() => ''}
                  renderOption={(subOrgId) =>
                    subOrgId === 'all' ? 'All (including future sub-organizations)' : subOrgIdToName[subOrgId]
                  }
                  className={classes.formTextField}
                  withOptionChips
                  disabledOptions={
                    shouldDisableFieldFunction(coverage?.is_org_level)
                      ? isAllSubOrgs
                        ? organization.sub_organizations.map((subOrg) => subOrg.id)
                        : coverage?.sub_organization_ids
                      : []
                  }
                  fullWidth
                  emptyChoiceValue={coverage?.sub_organization_ids || coverage?.is_org_level ? undefined : 'all'}
                />
              </div>
            </div>
          </Grid>
        )}
        <>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <div>
              <Typography
                display="block"
                variant="subtitle2"
                style={{ marginBottom: 10, fontWeight: 'bold', color: colorPalette.text.primary }}
              >
                Select Line of Business
              </Typography>
              <div style={{ display: 'flex' }}>
                {wizardLob ? (
                  <RadioWithButtonWrapperFormik
                    id="lob"
                    text={getLobDescription(wizardLob, lobConfigurationsDict)}
                    value={wizardLob}
                    disabled
                    icon={getLobIcon({ lob: wizardLob, lobConfigurationsDict })}
                    iconLocation="start"
                  />
                ) : (
                  <>
                    {availableLobsForSubOrgs?.length === 0 ||
                    (organization.sub_organizations_enabled && values.sub_organization_ids?.length === 0) ? (
                      <LobRadioFormik
                        lobFieldId="lob"
                        subOrganizationIds={organization.sub_organizations.map((s) => s.id)}
                        disabled
                      />
                    ) : (
                      <LobRadioFormik
                        lobFieldId="lob"
                        subOrganizationIds={
                          isAllSubOrgs ? organization.sub_organizations.map((s) => s.id) : values.sub_organization_ids
                        }
                        disabled={shouldDisableFieldFunction(coverage)}
                        filterLobsFunc={filterLobsFunction}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Grid>
        </>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TextFieldFormik
              id="display_name"
              label="Display Name"
              helperText={
                'The selected name will appear in the UI & reports' +
                (getIn(errors, 'display_name') && getIn(touched, 'display_name')
                  ? ' - ' + getIn(errors, 'display_name')
                  : '')
              }
              disabled={shouldDisableFieldFunction()}
              className={classes.formTextField}
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TextFieldFormik
              id="coverage_key"
              label="API Key"
              helperText={
                'The selected key will be used in api calls' +
                (getIn(errors, 'coverage_key') && getIn(touched, 'coverage_key')
                  ? ' - ' + getIn(errors, 'coverage_key')
                  : '')
              }
              disabled={shouldDisableFieldFunction(coverage)}
              className={classes.formTextField}
              fullWidth
            />
          </div>
        </Grid>
        {shouldShowISOCode && (
          <Grid item xs={6}>
            <AutocompleteFormik
              id="iso_code"
              label="ISO Code"
              options={Object.keys(COVERAGES_ISO_CODES)}
              getOptionLabel={(option) => (option ? `${option} (${COVERAGES_ISO_CODES[option]})` : '')}
              sortAlphabetic
              className={classes.formTextField}
              fullWidth
              disabled={shouldDisableFieldFunction()}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <AutocompleteFormik
            id="damage_assessment_type"
            label="Damage Assessment Type"
            options={Object.keys(DAMAGE_ASSESSMENT_TYPES)}
            getOptionLabel={(option) => DAMAGE_ASSESSMENT_TYPES[option]}
            sortAlphabetic
            className={classes.formTextField}
            fullWidth
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
        {organization.id && (
          <CoverageGroups
            organization={organization}
            coverage={coverage}
            shouldDisableFieldFunction={shouldDisableFieldFunction}
          />
        )}
        <Grid item xs={6}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="should_include_dates_of_service"
            label="Presenting dates of service in payments"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 24 }} spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '4px', fontWeight: 'bold' }}>
            Involved Entity
          </Typography>
          <Typography display="block" variant="body2">
            Select relevant entities the exposure could be opened on
          </Typography>
          <Typography display="block" variant="body2">
            Note: only one exposure can be opened on each involved entity per claim
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormik
            id="loss_type"
            label="Type of loss"
            className={classes.formTextField}
            fullWidth
            disabled={shouldDisableFieldFunction(coverage) || isSubmitting}
            select
          >
            {possibleLossTypes()
              .sort()
              .map((type) => (
                <MenuItem key={type} value={type}>
                  {LOSS_TYPES[type]['desc']}
                </MenuItem>
              ))}
          </TextFieldFormik>
        </Grid>
        <Grid item xs={8} style={{ marginBottom: 24 }}>
          <MultiSelectTextFieldFormik
            id="involved_types"
            label="Involved entity"
            disabled={isSubmitting}
            options={involvedTypesOption()}
            renderOption={(option) => involvedTypesRenderOption(option)}
            className={classes.formTextField}
            withOptionChips
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Coverage Limits and Deductible
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_limit_per_person"
            label="Limit per person / exposure"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_limit_per_incident"
            label="Limit per claim / incident"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_deductible"
            label="Deductible per exposure"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_coinsurance"
            label="Co-Insurance per exposure"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="is_limit_per_policy_per_entity"
            label="Limit per policy per entity"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
        <Grid item xs={4}>
          <SwitchFormik
            className={classes.formsSwitch}
            id="use_indemnity_sub_reserves"
            label="Indemnity sub reserves"
            withShowOnlyText
            disabled={shouldDisableFieldFunction(coverage)}
          />
        </Grid>
      </Grid>
      {values.use_indemnity_sub_reserves ? (
        <div>
          <SubReservesTypesDialogInner fieldId="sub_reserves_config" />
        </div>
      ) : null}
    </CardDialog>
  );
};

CoverageDialogInner.propTypes = {
  coverage: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  wizardLob: PropTypes.string,
  overrideDisabled: PropTypes.bool,
};

const CoverageGroups = ({ coverage, organization, shouldDisableFieldFunction }) => {
  const {
    isLoading: isLoadingCoveragesGroups,
    isError: isErrorCoveragesGroups,
    data: coveragesGroups,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/coverages/groups`);

  const shouldShowCoverageGroup = !isLoadingCoveragesGroups && !isErrorCoveragesGroups && coveragesGroups;
  const coveragesGroupsOptions = coveragesGroups?.filter(
    (group) => !group.is_disabled || coverage?.coverage_group_id === group.id
  );

  return shouldShowCoverageGroup ? (
    <>
      <Grid item xs={6}>
        <div className={styles.coverageGroupSelectWrapper}>
          <AutocompleteFormik
            id="coverage_group_id"
            label="Coverage Group"
            options={coveragesGroupsOptions}
            getOptionLabel={(option) => option.display_name}
            sortAlphabetic
            className={styles.coverageGroupSelect}
            disabled={shouldDisableFieldFunction()}
          />
          <TooltipIcon title="To add a group go to Coverage Group tab">
            <InfoIcon size={20} className={styles.coverageGroupInfoIcon} />
          </TooltipIcon>
        </div>
      </Grid>
      <Grid item xs={6} />
    </>
  ) : (
    <></>
  );
};

CoverageGroups.propTypes = {
  coverage: PropTypes.object,
  organization: PropTypes.object.isRequired,
  shouldDisableFieldFunction: PropTypes.func.isRequired,
};
