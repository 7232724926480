import React from 'react';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { Card } from '~/components/core/Cards/Card';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import LabeledValue from '~/components/core/Molecules/LabledValue';
import TextWithChips from '~/components/core/TextWithChips';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import RuleNotificationConfigurationFieldsFormik from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/RuleNotificationConfigurationFieldsFormik';
import type { PreDefinedNotification } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';
import { getLobDescription } from '~/Utils/lobUtils';

interface PreDefinedNotificationEditDialogInnerProps {
  notification: PreDefinedNotification;
  onClose: () => void;
}

export const PreDefinedNotificationEditDialogInner: React.FC<PreDefinedNotificationEditDialogInnerProps> = ({
  notification,
  onClose,
}) => {
  const classes = useStyles();
  const { isSubmitting, handleSubmit } = useFormikContext<PreDefinedNotification>();
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    organization,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    organizationOperationalDetails: { notificationsTokens },
  } = useSysconfig();
  const { lobConfigurationsDict } = useLobConfiguration();

  return (
    <CardDialog
      isDialog
      title="Edit Notification"
      fullWidth
      maxWidth="md"
      onClose={onClose}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onClose} />
      }
    >
      <div className="flex flex-col">
        <Card title="Context" cardType={Card.CARD_TYPES.PRIMARY}>
          <div className="grid grid-cols-2 gap-12 capitalize">
            {organization.sub_organizations_enabled && (
              <LabeledValue value={notification.sub_orgs} label="Sub Organizations" />
            )}
            <LabeledValue
              value={notification.lob === 'all' ? 'All' : getLobDescription(notification.lob, lobConfigurationsDict)}
              label="Line of business"
            />
            <div className="col-span-2">
              <RuleNotificationConfigurationFieldsFormik />
            </div>
            <LabeledValue value={notification.scope} label="Entity" />
            <LabeledValue value={notification.scope_types} label="Entity Type" />
          </div>
        </Card>
        <Card title="Details">
          <div className="grid grid-cols-2 gap-12 capitalize">
            <LabeledValue value={notification.rule_name} label="Rule Name" />
            <LabeledValue value={notification.recipient} label="Recipient" />
          </div>
        </Card>
        <Card title="Description">
          <div className="grid grid-cols-1 gap-12">
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={<TextWithChips text={notification.title} tokensValues={notificationsTokens} />}
              label="Notification Title"
              doNotRenderInTypography
            />
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={<TextWithChips text={notification.content} tokensValues={notificationsTokens} />}
              label="Notification Text"
              doNotRenderInTypography
            />
          </div>
        </Card>
      </div>
    </CardDialog>
  );
};

export default PreDefinedNotificationEditDialogInner;
