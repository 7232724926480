import React, { useMemo, useState } from 'react';
import axios from 'axios';

import { FsIconButton, LoadingSwitch, MainCard, Text } from '~/components/core';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SortableTable from '~/components/core/Tables/SortableTable';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import HoverChangeField from '~/components/HoverChangeField';
import { PencilIcon } from '~/components/icons';
import { FnolConfigurationDialog } from '~/components/SystemConfiguration/CliveConfiguration/Fnol/FnolConfigurationDialog';
import type {
  FnolConfigurationMetadata,
  FnolConfigurationPayload,
  FnolConfigurationRow,
  OrganizationFnolConfigurationResponse,
} from '~/components/SystemConfiguration/CliveConfiguration/Fnol/FnolConfigurationTypes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { OrganizationModel } from '~/components/types/organization-types';
import type { SubOrganization } from '~/components/types/sub-organization-types';
import useDataFetcher from '~/components/useDataFetcher';
import { serverDateToLocal } from '~/DateTimeUtils';
import { reportAxiosError } from '~/Utils';
import { getLobDescription } from '~/Utils/lobUtils';

const getSubOrganizationsConfigMap = (
  configs: Array<FnolConfigurationMetadata>
): Record<number, Record<string, FnolConfigurationMetadata>> => {
  const subOrgConfigMap: Record<number, Record<string, FnolConfigurationMetadata>> = {};
  for (const config of configs) {
    if (!subOrgConfigMap[config.sub_organization_id]) {
      subOrgConfigMap[config.sub_organization_id] = {};
    }
    subOrgConfigMap[config.sub_organization_id][config.lob] = config;
  }

  return subOrgConfigMap;
};

const getSubOrgRows = (
  subOrg: SubOrganization,
  configurations: Record<string, FnolConfigurationMetadata>
): Array<FnolConfigurationRow> => {
  return subOrg.supported_lobs.map((lob) => {
    const config = configurations[lob.lob];
    return {
      lob: lob.lob,
      sub_organization_id: subOrg.id,
      id: config?.id,
      is_configured: !!config,
      created_at: config?.created_at,
      updated_at: config?.updated_at,
    };
  });
};

export const CliveFnolConfigurationTab: React.FC = () => {
  const { organization } = useSysconfig() as { organization: OrganizationModel };
  const { lobConfigurationsDict } = useLobConfiguration();
  const baseConfigsRoute = `/sysconfig/api/v1/organizations/${organization?.id}/service_ai/configuration`;
  const baseFnolRoute = `/sysconfig/api/v1/organizations/${organization?.id}/service_ai/fnol_configuration`;

  const {
    isLoading: isFnolConfigsLoading,
    isError: isFnolConfigsError,
    data: fnolConfigs,
    reloadData: reloadFnolConfigs,
  } = useDataFetcher(baseFnolRoute) as {
    isLoading: boolean;
    isError: boolean;
    data: OrganizationFnolConfigurationResponse | undefined;
    reloadData: () => void;
  };

  const {
    isLoading: isSubOrgsConfigLoading,
    isError: isSubOrgsConfigError,
    data: subOrganizationsConfigs,
    reloadData: reloadSubOrganizationsConfig,
  } = useDataFetcher(baseConfigsRoute);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedConfigParams, setSelectedConfigParams] = useState<{
    subOrgId: number;
    lob: string;
    configurationId?: string;
  } | null>(null);

  const handleUpdateFnolInboxAddress = async (subOrg: SubOrganization, value: string): Promise<void> => {
    try {
      setIsSubmitting(true);

      const config = subOrganizationsConfigs[subOrg.id] || {};
      config.fnol_inbox_email_address = value;

      await axios.put(`${baseConfigsRoute}/${subOrg.id}`, config);
      await reloadSubOrganizationsConfig();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateFnolConfig = async (config: FnolConfigurationPayload) => {
    try {
      await axios.put(baseFnolRoute, config);
      reloadFnolConfigs();
      setSelectedConfigParams(null);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const subOrganizationsConfigMap = useMemo(() => {
    if (!fnolConfigs || !fnolConfigs.configurations) {
      return {};
    }

    return getSubOrganizationsConfigMap(fnolConfigs.configurations);
  }, [fnolConfigs]);

  const getSubOrg = (subOrgId: number) => {
    return organization?.sub_organizations?.find((subOrg) => subOrg.id === subOrgId);
  };

  const columns = [
    {
      id: 'lob',
      label: 'Line of Business',
      specialCell: (row: FnolConfigurationMetadata) => (
        <Chip label={getLobDescription(row.lob, lobConfigurationsDict)} size="small" className="mr-12" />
      ),
      disableSort: true,
    },
    {
      id: 'is_configured',
      label: 'Is Configured',
      specialCell: (row: FnolConfigurationRow) => (
        <Chip label={row.is_configured ? 'Yes' : 'No'} size="small" className="mr-12" />
      ),
      disableSort: true,
    },
    {
      id: 'created_at',
      label: 'Created At',
      specialCell: (row: FnolConfigurationRow) => <div>{row.created_at ? serverDateToLocal(row.created_at) : ''}</div>,
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      specialCell: (row: FnolConfigurationRow) => <div>{row.updated_at ? serverDateToLocal(row.updated_at) : ''}</div>,
    },
    {
      id: 'actions',
      label: 'Actions',
      width: 100,
      disableSort: true,
      specialCell: (row: FnolConfigurationRow) => {
        const isDisabled = isSubmitting;
        const lobDesc = getLobDescription(row.lob, lobConfigurationsDict);
        const tooltipText = `Edit ${lobDesc} configuration`;

        return (
          <FsIconButton
            disabled={isDisabled}
            tooltipText={tooltipText}
            onClick={() =>
              setSelectedConfigParams({
                subOrgId: row.sub_organization_id,
                lob: row.lob,
                configurationId: row.id,
              })
            }
          >
            <PencilIcon />
          </FsIconButton>
        );
      },
    },
  ];

  const isLoading = isFnolConfigsLoading || isSubOrgsConfigLoading;

  return (
    <LoadingSwitch isLoading={isLoading} isError={isFnolConfigsError || isSubOrgsConfigError}>
      <div className="flex flex-col gap-16 p-20">
        {organization?.sub_organizations?.map((subOrg) => {
          const rows = subOrganizationsConfigMap
            ? getSubOrgRows(subOrg, subOrganizationsConfigMap[subOrg.id] || [])
            : [];
          return (
            <MainCard type="elevated" collapsible openByDefault={true} key={subOrg.id} title={subOrg.name}>
              <Grid container direction="column" key={subOrg.id}>
                <div className="mb-20 flex w-full items-center">
                  <div className="flex">
                    <Text variant={Text.VARIANTS.XS}>FNOL Inbox Email Address</Text>
                  </div>
                  <div className="ml-20 flex">
                    <HoverChangeField
                      permanent
                      label="FNOL Inbox Address"
                      name="fnol_inbox_email_address"
                      disabled={isSubmitting}
                      value={
                        subOrganizationsConfigs ? subOrganizationsConfigs[subOrg.id]?.fnol_inbox_email_address : null
                      }
                      onUpdate={async ({ fnol_inbox_email_address }: { fnol_inbox_email_address: string }) =>
                        await handleUpdateFnolInboxAddress(subOrg, fnol_inbox_email_address)
                      }
                    />
                  </div>
                </div>
                <Grid item xs={12} className="mb-20">
                  <SortableTable columns={columns} rows={rows} />
                </Grid>
              </Grid>
            </MainCard>
          );
        })}
      </div>
      {selectedConfigParams && (
        <FnolConfigurationDialog
          subOrganization={getSubOrg(selectedConfigParams.subOrgId) as SubOrganization}
          lob={selectedConfigParams.lob}
          onSaveConfiguration={handleUpdateFnolConfig}
          configurationId={selectedConfigParams.configurationId}
          onClose={() => setSelectedConfigParams(null)}
        />
      )}
    </LoadingSwitch>
  );
};
