import type { ReactElement } from 'react';
import React from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import type { EditConfigurationDialogComponentProps } from '~/components/core/Molecules/ConfigurableOption';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import TextFieldFormik from '~/components/TextFieldFormik';
import { reportErrorInProductionOrThrow } from '~/Utils';

const showAllMineOptions: Record<string, string> = { true: 'Show All', false: 'Show Mine' };

const NotificationsShowAllMineEditDialog = ({
  configuration,
  onCancel = noop,
  onSubmit,
}: EditConfigurationDialogComponentProps<NotificationsPreferences>): ReactElement => {
  const classes = useStyles();

  const handleSubmit = async (
    values: Partial<NotificationsPreferences>,
    formikHelpers: FormikHelpers<Partial<NotificationsPreferences>>
  ) => {
    try {
      if (!onSubmit) {
        reportErrorInProductionOrThrow('No onSubmit function for NotificationsShowAllMineEditDialog');
        return;
      }
      await onSubmit({
        is_default_view_all_in_claims_page: Boolean(values.is_default_view_all_in_claims_page),
      });
      onCancel();
    } catch {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        is_default_view_all_in_claims_page: configuration?.is_default_view_all_in_claims_page || false,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Claim notification view"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                primaryLabel="Save"
                onClickPrimary={handleSubmit}
                onClickSecondary={onCancel}
                disabled={isSubmitting}
              />
            }
          >
            <TextFieldFormik
              id="is_default_view_all_in_claims_page"
              select
              label="Default claim notification view"
              disabled={isSubmitting}
              className={classes.textField}
              fullWidth
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              {Object.keys(showAllMineOptions).map((key: boolean) => {
                const stringKey = String(key);
                return (
                  <MenuItem key={stringKey} value={stringKey}>
                    {showAllMineOptions[stringKey]}
                  </MenuItem>
                );
              })}
            </TextFieldFormik>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default NotificationsShowAllMineEditDialog;
