import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { EyeIcon, FileIcon, PencilIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

interface GenericTemplateCardButtonsContainerProps {
  handleEditClick: () => void;
  handlePreviewClick: () => void;
  downloadUrl?: string;
}
const GenericTemplateCardButtonsContainer: React.FC<GenericTemplateCardButtonsContainerProps> = ({
  handleEditClick,
  handlePreviewClick,
  downloadUrl,
}) => {
  // Generate example is only available in the external admin
  const isInternalAdminRoute = location.pathname.startsWith('/admin');

  return (
    <div className="m-12 flex items-center justify-between">
      <div>
        <Tooltip title="Generate example is only available in the external admin" disabled={!isInternalAdminRoute}>
          <div>
            <Button color="primary" disabled={isInternalAdminRoute} onClick={() => handlePreviewClick()}>
              <EyeIcon
                iconColor={!isInternalAdminRoute ? colors.teal['700'] : undefined}
                className={cn('mr-4', { 'opacity-40': isInternalAdminRoute })}
              />
              Generate example
            </Button>
          </div>
        </Tooltip>
      </div>
      <div>
        <div className="flex items-center">
          {downloadUrl ? (
            <Tooltip title="Download">
              <a href={downloadUrl} target="_blank" rel="noreferrer">
                <div className="mt-2">
                  <FileIcon className="cursor-pointer hover:bg-transparent hover:stroke-teal-700" />
                </div>
              </a>
            </Tooltip>
          ) : null}
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClick()} className="hover:bg-transparent">
              <PencilIcon className="cursor-pointer hover:stroke-teal-700" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default GenericTemplateCardButtonsContainer;
